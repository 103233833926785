// This code is reusable - one use case is in "app/views/dealerships/edit_stock_configuration.html.erb".
/*******************
      Purpose
********************/
// When having 2 select elements, where the first (primary) changes the result set loaded in the second (secondary),
// the JS here is used to load the data for the secondary select from an API as the first <select> changes.
// ".js-primary-select" is the class name of the primary <select> & ".js-secondary-select" is the class name for secondary <select>.
/*******************
    How to use it
********************/
// The primary select should have the following attributes and class name
// <select
// data-request-url="<%= options_stock_collection_path(':id') %>" - 'id' will be replaced by primary select value
// data-text-for-when-no-sub-category-records-found="The selected stock collection has no publish setups"
// class="js-primary-select">
// </select>
// The secondary select should have the following class name
// <select class="js-secondary-select">

// ChoicesJS is also used to make a long list of <option>s more user friendly in the UI.

import Choices from "choices.js";

CMS = CMS || {};

const populateSecondarySelect = () => {
  const loads = ["populate_secondary_select_with_data"];

  const createChoicesListFromJson = (availableChoices, previousValue) => {
    const choicesList = [];
    availableChoices.forEach((choice) => {
      // This method returns a structure like -> [{label: "stock_1", value: "stock 1", selected: true/false},{...}]
      // Where "selected" is set to true for an iteration, if its found in "arrayOfValuesIndicatingSelectedOptions"
      choicesList.push({
        label: choice["name"],
        value: choice["id"],
        selected: choice["id"] == previousValue,
      });
    });
    return choicesList;
  };

  const populateSecondarySelectWithData = async () => {
    const primarySelect = document.querySelector(".js-primary-select");
    const secondarySelect = document.querySelector(".js-secondary-select");
    if (!primarySelect || !secondarySelect) return;

    const primarySelectChoices = new Choices(primarySelect); // Create a new `Choices` instance for the primary select
    primarySelectChoices.init();

    const secondaryChoicesSelect = new Choices(secondarySelect); // Create a new `Choices` instance for the secondary select

    primarySelect.addEventListener("change", async (e) => {
      loadSecondarySelect(e.target.value, primarySelect, primarySelectChoices, secondaryChoicesSelect);
    });

    if (primarySelectChoices.getValue(true).length > 0) {
      loadSecondarySelect(primarySelect.value, primarySelect, primarySelectChoices, secondaryChoicesSelect);
    }
  };

  const loadSecondarySelect = (requestedRecordId, primarySelect, primarySelectChoices, secondaryChoicesSelect) => {
    const urlWithoutId = primarySelect.dataset.requestUrl;
    const urlWithId = urlWithoutId.replace(/:id/, `${requestedRecordId}`);
    const previousValue = secondaryChoicesSelect.getValue(true);

    // Removing all existing items in secondary select
    secondaryChoicesSelect.clearChoices();
    // Removing all pre-existing items in secondary select
    secondaryChoicesSelect.removeActiveItems();
    // Add place holder value
    secondaryChoicesSelect._placeholderValue = "Select a Publish Setup";

    if (!requestedRecordId || !urlWithoutId || !urlWithId) return;

    // Add new items to choices select
    secondaryChoicesSelect.setChoices(async () => {
      try {
        const items = await fetch(urlWithId);
        const jsonItems = await items.json();
        return createChoicesListFromJson(jsonItems, previousValue);
      } catch (err) {
        console.error(err);
      }
      return [];
    });
  };

  return {
    loads: loads,
    populate_secondary_select_with_data: populateSecondarySelectWithData,
  };
};

CMS.populateSecondarySelect = populateSecondarySelect();
