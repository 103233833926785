import {
  dropHandler,
  startDragHandler,
  isDropValid,
  parseDraggedData,
  dragoverHandler,
  fetchAjax,
  executeResponse
} from "./drag_drop_functionality";

CMS = CMS || {};

const pageConfigurator = () => {
  const loads = ["add_columns_listeners", "add_before_cache_listener"];
  const addColumnsListeners = () => {
    const columnsContainer = document.querySelector(".js-layout-columns-dropable-wrapper");
    if (null !== columnsContainer) {
      columnsContainer.addEventListener("click", columnClickedListener);

      const event = {
        target: document.querySelector(".js-page-column-click")
      };
      columnClickedListener(event);
      document
        .querySelector(".js-components-draggable-container")
        .addEventListener("dragstart", componentDragstartHandler);
    }
  };

  const addBeforeCacheListener = () => {
    document.addEventListener("turbolinks:before-cache", () => {
      const componentListContainer = document.getElementById("column-components-list");
      const target = document.querySelector(".js-page-column-click");
      if (null === componentListContainer || null === target) return;

      componentListContainer.innerHTML = "";
      document.querySelectorAll(".js-page-column-click").forEach(el => {
        el.classList.add("disabled");
        el.removeEventListener("dragover", columnDragoverHandler);
        el.removeEventListener("drop", columnDropHandler);
      });
      target.classList.remove("disabled");
      target.addEventListener("dragover", columnDragoverHandler);
      target.addEventListener("drop", columnDropHandler);
    });
  };

  const columnClickedListener = async event => {
    if (null === event.target) return;
    const target = event.target.closest(".js-page-column-click");
    if (null !== target) {
      const response = await fetchAjax(target.dataset.componentsListUrl);
      executeResponse(response);
      document.querySelectorAll(".js-page-column-click").forEach(el => {
        el.classList.add("disabled");
        el.removeEventListener("dragover", columnDragoverHandler);
        el.removeEventListener("drop", columnDropHandler);
      });
      target.classList.remove("disabled");
      target.addEventListener("dragover", columnDragoverHandler);
      target.addEventListener("drop", columnDropHandler);
    }
    showColumnComponentsList();
  };

  const componentDragstartHandler = event => {
    startDragHandler(event);
  };

  const columnDragoverHandler = event => {
    dragoverHandler(event);
  };

  const columnDropHandler = async event => {
    event.preventDefault();
    const draggedData = event.dataTransfer.getData("text/html");
    if (isDropValid(event, draggedData)) {
      const object = parseDraggedData(draggedData);
      const list = event.target.closest(".js-drop-zone").querySelector(".js-dropped-list");
      const data = {
        component_components_instance: {
          component_id: object.dataset.componentId,
          page_column_id: object.dataset.pageColumnId,
          page_id: object.dataset.pageId,
          position: list.children.length
        }
      };
      dropHandler(list.dataset.createUrl, data, "POST", list.children);
    }
  };

  const showColumnComponentsList = () => {
    var componentList = document.getElementById("column-components-list");
    var dropZoneColumn = document.querySelectorAll(".js-drop-zone");

    for (var i = 0; i < dropZoneColumn.length; i++) {
      dropZoneColumn[i].onclick = function() {
        if (componentList.classList.contains("active-toggle") == true) {
          componentList.classList.remove("active-toggle");
          componentList.classList.add("toggle-active");
        } else if (componentList.classList.contains("toggle-active") == true) {
          componentList.classList.remove("toggle-active");
          componentList.classList.add("active-toggle");
        } else {
          componentList.classList.add("active-toggle");
        }
      };
    }
  };

  return {
    loads: loads,
    add_columns_listeners: addColumnsListeners,
    component_dragstart_handler: componentDragstartHandler,
    add_before_cache_listener: addBeforeCacheListener
  };
};

CMS.pageConfigurator = pageConfigurator();
