CMS = CMS || {};

const bannerPicker = () => {
  const loads = ["add_banner_picker_listener", "click_add_banner_picker_listener"];

  var prevBannerId = 0;
  var prevAltText = "";
  var prevUrl = "";

  const addBannerPickerListener = () => {
    const container = document.querySelector(".js-banner-picker-configuration-container");
    if (container) {
      container.addEventListener("click", pickerSaveOrCancelOrCloseClickHandler);
      showHideBannerImage();
    }

    const removeButton = document.querySelector(".js-page-banner-remove-btn");
    if (removeButton) {
      removeButton.addEventListener("click", function () {
        setBannerData();
      });
    }
  };

  const setBannerData = (bannerId = 0, url = "", altText = "") => {
    if (bannerId > 0) document.getElementById("page_banner_id_" + bannerId).checked = "checked";
    else if (document.querySelector('input[name="page[banner_id]"]:checked') != null)
      document.querySelector('input[name="page[banner_id]"]:checked').checked = false;
    document.getElementById("js-banner-selected-image-url").value = url;
    document.querySelector('input[name="page[banner_alt_text]"]').value = altText;
    showHideBannerImage();
  };

  const showHideBannerImage = () => {
    const bannerUrl = document.getElementById("js-banner-selected-image-url");
    const bannerDisplay = document.getElementById("js-page-banner-display");
    const bannerThumb = document.getElementById("js-page-banner-thumbnail");
    const bannerName = document.getElementById("js-page-banner-name");
    if (bannerUrl.value != "") {
      document.getElementById("js-selected-banner-url").href = bannerUrl.value;
      document.getElementById("js-selected-banner-image").src = bannerUrl.value;
      bannerName.textContent = document
        .querySelector('input[name="page[banner_id]"]:checked')
        .getAttribute("data-fileName");
      bannerName.href = bannerUrl.value;
      document.getElementById("js-page-banner-date").textContent = document
        .querySelector('input[name="page[banner_id]"]:checked')
        .getAttribute("data-updated");
      bannerDisplay.classList.add("active");
      bannerThumb.classList.remove("active");
    } else {
      bannerDisplay.classList.remove("active");
      bannerThumb.classList.add("active");
    }
  };

  const closeModal = () => {
    const parent = document.getElementById("js-banner-picker-configuration-overlay");
    if (parent.classList.contains("active") === true) {
      parent.classList.remove("active");
    }
  };

  const pickerSaveOrCancelOrCloseClickHandler = (event) => {
    if (event.target.classList.contains("js-save-button")) {
      var bannerUrl = document.querySelector('input[name="page[banner_id]"]:checked').getAttribute("data-url");
      document.getElementById("js-banner-selected-image-url").value = bannerUrl;
      document.getElementById("page_banner_alt_text").value = document
        .querySelector('input[name="page[banner_id]"]:checked')
        .getAttribute("data-altText");
      document.getElementById("js-page-banner-name").textContent = document
        .querySelector('input[name="page[banner_id]"]:checked')
        .getAttribute("data-fileName");
      document.getElementById("js-page-banner-name").href = bannerUrl;
      document.getElementById("js-page-banner-date").textContent = document
        .querySelector('input[name="page[banner_id]"]:checked')
        .getAttribute("data-updated");
      showHideBannerImage();
      closeModal();
    } else if (event.target.classList.contains("js-close-button")) {
      setBannerData(prevBannerId, prevUrl, prevAltText);
      closeModal();
    }
  };

  const addBannerIconClickedEventListener = () => {
    const items = document.querySelectorAll(".js-banner-edit-clicked");
    if (items) {
      items.forEach((element) => {
        element.querySelectorAll(".js-banner-icon-clicked").forEach((item) => {
          item.addEventListener("click", bannerIconClicked);
        });
      });
    }
  };

  const bannerIconClicked = (event) => {
    const target = event.target.closest(".js-banner-edit-clicked");
    if (target) {
      var modalElem = document.getElementById("js-banner-picker-configuration-overlay");
      modalElem.classList.add("active");
      if (document.querySelector('input[name="page[banner_id]"]:checked') != null)
        prevBannerId = document.querySelector('input[name="page[banner_id]"]:checked').value;
      prevAltText = document.querySelector('input[name="page[banner_alt_text]"]').value;
      prevUrl = document.getElementById("js-banner-selected-image-url").value;
    }
  };

  return {
    loads: loads,
    add_banner_picker_listener: addBannerPickerListener,
    click_add_banner_picker_listener: addBannerIconClickedEventListener,
  };
};

CMS.bannerPicker = bannerPicker();
