CMS = CMS || {};

const assetCategories = () => {
  const loads = ["set_slug_on_name_change", "set_dimenstions_change"];

  const setSlugOnNameChange = () => {
    const nameInput = document.querySelector('.js-asset-category-name-changer')
    if (nameInput) {
      nameInput.addEventListener("change", replicateNameToSlug)
    }
  }

  const replicateNameToSlug = (event) => {
    event.preventDefault()
    const slugInput = document.querySelector('.js-asset-category-slug-changer')
    const nameValue = event.target.value
    const slugValue = nameValue.replace(/ /g,"_").toLowerCase()
    slugInput.value = slugValue
  }

  const setDimensionsChange = () => {
    const dimensionsCheckbox = document.querySelector('.js-asset-category-dimensions-checkbox')
    if (dimensionsCheckbox) {
      dimensionsCheckbox.addEventListener("click", toggleDimensionsBlock)
    }
  }

  const toggleDimensionsBlock = (event) => {
    const dimensionsBlock = document.querySelector('.js-asset-category-dimensions-block')

    if (dimensionsBlock && event.target.checked) {
      dimensionsBlock.classList.remove('is-visible')
      dimensionsBlock.classList.add('is-not-visible')
    } else {
      dimensionsBlock.classList.remove('is-not-visible')
      dimensionsBlock.classList.add('is-visible')
    }
  }

  return {
    loads: loads,
    set_slug_on_name_change: setSlugOnNameChange,
    set_dimenstions_change: setDimensionsChange
  };
};

CMS.assetCategories = assetCategories();
