CMS = CMS || {};

const formDisableSaveBeforeEdit = () => {
  const loads = ["handle_form_disable_save_before_edit_on_load"];

  const handleFormDisableSaveBeforeEditOnLoad = () => {
    const targetButton = document.querySelector("#js-disable-save-before-edit");

    if (!targetButton) return false;

    $(document).on("change", ":input",
      function () {
        targetButton.disabled = false;
      }
    );
  };

  return {
    loads: loads,
    handle_form_disable_save_before_edit_on_load: handleFormDisableSaveBeforeEditOnLoad,
  };
};

CMS.formDisableSaveBeforeEdit = formDisableSaveBeforeEdit();
