CMS = CMS || {};

const EqualsAndNotEqualsRuleForms = () => {
  const loads = [
    "generate_checkbox",
    "toggle_rule_value_set_based_on_source_type",
    "initiate_choices_select_on_page_load",
    "initiate_choices_select_on_change",
  ];

  const generateCheckbox = () => {
    let ruleValueLists = document.querySelectorAll(".js-rule-value-list");

    // Create the custom checkboxes by gathering data from the <input>
    ruleValueLists.forEach((ruleValueList) => {
      let buttonAddRuleValue = ruleValueList.querySelector(".js-add-fields");
      if (!buttonAddRuleValue) return false;

      // Add custom behavior to the <input> used for adding custom checkboxes
      addCustomBehaviourToCheckboxInputField(ruleValueList);

      // Add custom checkboxes using values typed in <input>
      buttonAddRuleValue.addEventListener("click", (event) => {
        event.preventDefault();
        let dataField = event.currentTarget.dataset["fields"];
        const dataFieldBluePrint = dataField;

        let userSubmittedValue = ruleValueList.querySelector(".js-checkbox-user-typed-input");
        userSubmittedValue = userSubmittedValue.value;
        if (!userSubmittedValue) return false;

        // If checkbox's value is a duplicate, don't allow adding it as a new item
        let checkBoxValid = true;
        let existingCheckboxes = ruleValueList.querySelectorAll(".js-rule-checkbox-input");
        existingCheckboxes.forEach((existingCheckbox) => {
          if (existingCheckbox.value == userSubmittedValue) checkBoxValid = false;
        });
        if (checkBoxValid == false) {
          alert("This value already exists in the list of checkboxes. Please try creating a different one.");
          // Reset "data-field" to its original structure to enable the addition of new values
          event.currentTarget.dataset["fields"] = dataFieldBluePrint;
          return false;
        }

        // Extract entered data from <input>
        let userSubmittedValueUnderscored = userSubmittedValue.replace(/\s/g, "_");
        let modifiedDataField = dataField.replace(/:user_submitted_value_underscored/g, userSubmittedValueUnderscored);
        modifiedDataField = dataField.replace(/:user_submitted_value/g, userSubmittedValue);
        modifiedDataField.replace(/"/g, "'");
        event.currentTarget.dataset["fields"] = modifiedDataField;

        // Parse text to html - nodes are required for ".appendChild()", text is not allowed.
        let parser = new DOMParser();
        var checkBoxElement = parser.parseFromString(event.currentTarget.dataset["fields"], "text/html");
        checkBoxElement = checkBoxElement.querySelector(".js-user-created-checkbox-unit");

        // Insert new checkbox elements (parsed) into dom
        let userCreatedCheckboxListWrapper = ruleValueList.querySelector(".js-user-created-checkbox-list-wrapper");
        userCreatedCheckboxListWrapper.appendChild(checkBoxElement);

        // Reset "data-field" to its original structure to enable the addition of new values
        event.currentTarget.dataset["fields"] = dataFieldBluePrint;
        // Reset the submitted <input> box's value to be nothing again - ready to accept new values.
        ruleValueList.querySelector(".js-checkbox-user-typed-input").value = "";
        event.currentTarget.classList.add("imf-btn-disabled");
      });
    });
  };

  const addCustomBehaviourToCheckboxInputField = (ruleValueList) => {
    // Add custom behavior to the <input> used for adding custom checkboxes
    let checkboxInput = ruleValueList.querySelector(".js-checkbox-user-typed-input");

    // Dim/show button when typing text inside the <input> used for creating custom checkboxes
    checkboxInput.addEventListener("input", (event) => {
      let inputValue = event.currentTarget;
      let siblingButton = event.currentTarget.nextElementSibling;

      // Add and remove class on sibling ("Add" button) that dims the btn.
      if (inputValue.value.length < 1 && inputValue.classList.contains("imf-btn-disabled") == false) {
        siblingButton.classList.add("imf-btn-disabled");
      } else if (inputValue.value.length > 0) {
        siblingButton.classList.remove("imf-btn-disabled");
      }
    });

    // When hitting return inside the <input> (while adding a custom checkbox) create the custom checkbox.
    checkboxInput.addEventListener("keydown", (event) => {
      if (event.keyCode == 13) {
        event.preventDefault();
        let siblingButton = event.currentTarget.nextElementSibling;
        siblingButton.click();
        siblingButton.classList.add("imf-btn-disabled");
      }
    });
  };

  const toggleRuleValueSetBasedOnSourceType = () => {
    let ruleSourceSelect = document.querySelector(".js-rules-selected-source");
    if (!ruleSourceSelect) return false;
    ruleSourceSelect.addEventListener("change", (event) => {
      let selectedValue = event.currentTarget.value;
      if (!selectedValue) selectedValue = "blank";
      // changes "stock_type" to "stock-type" (for ex) to meet html class name format
      selectedValue = selectedValue.replace(/_/g, "-");
      // this constructed class name will be used to target a set of available rule values, based on the selected source.
      let constructedRuleValueTypeClassName = `.js-${selectedValue}-rule-values`;
      let targetedSetOfRuleValues = document.querySelector(constructedRuleValueTypeClassName);

      // Hide all the wrappers that contain the different set of "rule values"
      let ruleSetWrappers = document.querySelectorAll(".js-rule-value-set-wrapper");
      ruleSetWrappers.forEach((ruleSetWrapper) => {
        if (ruleSetWrapper.classList.contains("hidden") == false) ruleSetWrapper.classList.add("hidden");
      });

      // Show the the wrapper with the "rule value" set that matches the selected "rule source"
      targetedSetOfRuleValues.classList.remove("hidden");
    });
  };

  const initiateChoicesSelectOnPageLoad = () => {
    // This method initiates a single choices JS <select> (populated with data) when the page loads,
    // It only does this the pre-selected rule value requires it.
    let primarySelect = document.querySelector(".js-choices-primary-select-option");
    let selectWrapper = document.querySelectorAll(".js-equals-rules-choices-select-wrapper");

    if (!selectWrapper || !primarySelect) return false;

    selectWrapper.forEach(async (element) => {
      // When the page loads, fetch & load pre-selected <option>s in the choices <select>
      await CMS.choices.create_choices_select(element);
    });
  };

  const initiateChoicesSelectOnChange = () => {
    // If a primary select (source) is toggled, and the selected value is 'feed_id', 'make' or 'yard',
    // then fetch the data to populate the corresponding choices <select> with <option>s.
    let primarySelect = document.querySelector(".js-choices-primary-select-option");
    if (!primarySelect) return false;

    primarySelect.addEventListener("change", async (e) => {
      let selectedValue = e.currentTarget.value;
      if (selectedValue == "feed_id" || selectedValue == "make" || selectedValue == "yard") {
        // Changes "stock_type" to "stock-type" (for ex) to meet html class name format
        selectedValue = selectedValue.replace(/_/g, "-");
        // The constructed class name will be used to target a <select>
        let constructedRuleValueTypeClassName = `.js-${selectedValue}-available-choices`;
        let targetedSecondaryChoicesSelectWrapper = document.querySelector(constructedRuleValueTypeClassName);

        // If the choices select options have already been created (previously toggled already), do nothing.
        if (targetedSecondaryChoicesSelectWrapper.querySelector(".js-choices-multiples-select")) return false;
        // Add the choices <select> in the UI and fetch the data it requires to populate the <option>s
        await CMS.choices.create_choices_select(targetedSecondaryChoicesSelectWrapper);
      }
    });
  };

  return {
    loads: loads,
    generate_checkbox: generateCheckbox,
    toggle_rule_value_set_based_on_source_type: toggleRuleValueSetBasedOnSourceType,
    initiate_choices_select_on_page_load: initiateChoicesSelectOnPageLoad,
    initiate_choices_select_on_change: initiateChoicesSelectOnChange,
  };
};

CMS.EqualsAndNotEqualsRuleForms = EqualsAndNotEqualsRuleForms();
