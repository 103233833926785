/*

Add ajax:success event listener functionality to promo label forms and promo label delete links on stock listing page. Delegated to a wrapper <ul#js-stock-index>
Re-register text field counter event listeners.

*/

CMS = CMS || {};

const stock_promo_labels = () => {
  "use strict";
  const loads = ["register_ajax_success_callback"];

  const stockPromoLabelAjaxSuccess = event => {
    // https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    var detail = event.detail;
    var xhr = detail[2];

    if (event.target && event.target.nodeName === "FORM") {
      // via ajax form submission
      event.target.outerHTML = xhr.responseText;
    } else if (event.target && event.target.nodeName === "A") {
      // via ajax link with form id as `data-form`
      if (event.target.dataset && event.target.dataset.form) {
        document.querySelector(`#${event.target.dataset.form}`).outerHTML = xhr.responseText;
      }
    }
  };

  const registerAjaxSuccessCallback = () => {
    // Register event listeners for every promo label form: `ajax:success` of data-remote form submissions
    var stock_promo_labels_forms_list = document.querySelector("#js-stock-index");
    if (stock_promo_labels_forms_list !== null) {
      stock_promo_labels_forms_list.addEventListener(
        "ajax:success",
        event => {
          stockPromoLabelAjaxSuccess(event);
          CMS.text_field_counter.register_text_field_counters();
        },
        false
      );
    }
  };

  return {
    loads: loads,
    register_ajax_success_callback: registerAjaxSuccessCallback
  };
};

CMS.stock_promo_labels = stock_promo_labels();
