const { toggleClass } = require("./helper");

class Redbook {
  constructor() {
    this.hasPurifyElements = false;
    this.initPurifyData();
    this.initMakeChanges();
  }

  initPurifyData() {
    const purifyButtonElement = document.getElementById("purify_vehicle_data");
    if (purifyButtonElement) {
      this.hasPurifyElements = true;
      purifyButtonElement.addEventListener("click", () => {
        toggleClass(document.getElementById("vehicle-identifiable-attributes"), "hidden");
        toggleClass(document.getElementById("redbook-purification-selector"), "hidden");
      });
    }
  }

  initMakeChanges() {
    if (!this.hasPurifyElements) {
      return;
    }
    //adding the Event Listeners to all elements (and future elements)
    this.addCustomEventListener(".js-redbook-purify", "change", this.taskClickHandler);
  }

  taskClickHandler(event) {
    Rails.ajax({
      url: event.target.options[event.target.selectedIndex].dataset.url,
      type: "get",
    });
  }

  addCustomEventListener(selector, event, handler) {
    // A custom event listener to automatically attach events to future DOM elements.
    // https://dev.to/akhil_001/adding-event-listeners-to-the-future-dom-elements-using-event-bubbling-3cp1
    let rootElement = document.querySelector("body");
    //since the root element is set to be body for our current dealings
    rootElement.addEventListener(
      event,
      function (evt) {
        var targetElement = evt.target;
        while (targetElement != null) {
          if (targetElement.matches(selector)) {
            handler(evt);
            return;
          }
          targetElement = targetElement.parentElement;
        }
      },
      true
    );
  }

  purifyData(redbookData) {
    this.redbookData = redbookData;
    const newStock = document.getElementById("is_new_stock");
    const type = document.getElementById("type");
    let prefix;
    if (!newStock) return;
    if (type) {
      switch (type.value) {
        case "Car":
          prefix = "#types_car_";
          break;
        default:
          prefix = "#stock_";
          break;
      }
    } else {
      prefix = "#stock_";
    }
    document.querySelector(prefix + "redbook_vehicle_key").value = redbookData.vehicle_key;
    document.querySelector(prefix + "make").value = document.querySelector("#rb_make").value;
    document.querySelector(prefix + "model").value = document.querySelector("#rb_model").value;
    document.querySelector(prefix + "year").value = redbookData.year_group;
    document.querySelector(prefix + "variations").value = [
      redbookData.badge_description,
      redbookData.badge_secondary_description,
    ]
      .join(" ")
      .trim();
    document.querySelector(prefix + "series").value = [redbookData.series_public, redbookData.series_model_year]
      .join(" ")
      .trim();
    document.querySelector(prefix + "trans_desc").value = redbookData.gear_type_description;
    document.querySelector(prefix + "trans_num").value = redbookData.gear_num;
    document.querySelector(prefix + "body").value = redbookData.body_style_description;
    document.querySelector(prefix + "drive_type").value = redbookData.drive_description;
    document.querySelector(prefix + "fuel_type").value = redbookData.fuel_type_description;
    document.querySelector(prefix + "size").value = redbookData.engine_description;
    document.querySelector(prefix + "size_option").value = this.purifiedSizeOption();
    document.querySelector(prefix + "fuel_system").value = this.purifiedFuelSystem();
    document.querySelector(prefix + "cylinders").value = redbookData.cylinders;
    document.querySelector(prefix + "passenger_doors").value = redbookData.door_num;
    document.querySelector(prefix + "seats").value = redbookData.seat_capacity;
    document.querySelector(prefix + "power").value = redbookData.power;
  }

  purifiedSizeOption() {
    if (!Number(this.redbookData.engine_description)) return "CC";
    return Number(this.redbookData.engine_description) > 50 ? "CC" : "Litres";
  }

  purifiedFuelSystem() {
    let fuelSystem = "";
    if (this.redbookData.cylinders) fuelSystem = this.redbookData.cylinders + " Cyl ";
    fuelSystem = fuelSystem + this.redbookData.fuel_delivery_description;
    return fuelSystem.trim();
  }
}

module.exports = Redbook;
