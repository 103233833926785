CMS = CMS || {};

document.addEventListener("turbolinks:load", function () {
  var asset_drop_target = document.getElementById("asset-drop-target");

  if (asset_drop_target !== null) {
    // drag and drop event listeners
    asset_drop_target.addEventListener("dragenter", AssetUploaderForAssetModel.asset_drag_hover, false);
    asset_drop_target.addEventListener("dragover", AssetUploaderForAssetModel.asset_drag_hover, false);
    asset_drop_target.addEventListener("dragleave", AssetUploaderForAssetModel.asset_drag_hover, false);
  }
});

function AssetUploaderForAssetModel(init_attribute, file, init_asset, externalInputElement) {
  "use strict";

  this.attribute = init_attribute;
  // this.value = init_value;
  this.element = externalInputElement;
  this.asset = init_asset;
  this.base64 = "";
  this.attribute_field = document.getElementById(this.attribute);
  // this.base64_field = document.getElementById(this.attribute + '_base64');
  this.base64_field = externalInputElement;
  this.status_element = document.getElementById(this.attribute + "_status");
  this.attribute_link = document.getElementById(this.attribute + "_link");
  this.allowed_types = this.asset.allowed_types;
  this.mime_type = "";
  this.maximum_file_size_mb = this.asset.maximum_file_size_mb; // remember, base64 will add approx 33%
  this.errors = [];
  if (this.asset instanceof CMS.image_uploader_for_asset_model) {
    this.image_restrictions = this.asset.category_width + "px by " + this.asset.category_height + "px";
  }

  this.attach_events = function () {
    var _this = this;
    // add asset
    var add_asset_link = externalInputElement.parentElement.querySelector(".js-asset-upload-btn");
    add_asset_link.addEventListener(
      "click",
      function (e) {
        _this.display_overlay(e);
      },
      false
    );
    // replace asset
    var replace_asset_link = externalInputElement.parentElement.querySelector(".js-asset-replace-btn");
    replace_asset_link.addEventListener(
      "click",
      function (e) {
        _this.display_overlay(e);
      },
      false
    );

    // file select, drag and drop event listeners
    var select_asset = externalInputElement;
    select_asset.addEventListener(
      "change",
      function (e) {
        AssetUploaderForAssetModel.asset_file_handler(e, _this);
      },
      false
    );

    // assign event listeners to variables so that they can be removed later
    _this.asset_drop_target_listener = function (e) {
      AssetUploaderForAssetModel.asset_file_handler(e, _this);
    };
    _this.asset_upload_outer_listener = function (e) {
      _this.hide_overlay(e);
    };
    _this.select_asset_link_listener = function (e) {
      _this.asset_file_trigger(e);
    };

    // remove image preview for cropped image if 'Remove' button clicked
    const removeButton = externalInputElement.parentElement.parentElement.parentElement
      .querySelector(".js-au-asset-remove-wrapper")
      .querySelector(".js-asset-remove-btn");
    if (removeButton) {
      removeButton.addEventListener("click", function () {
        const anchor = externalInputElement.parentElement.querySelector(".js-anchor");
        const croppedAssetPreview = externalInputElement.parentElement.querySelector(".js-cropped-asset-preview");
        const defaultAssetImage = externalInputElement.parentElement.querySelector(".js-asset-img-svg");
        const base64InputField = externalInputElement.parentElement.querySelector(".js-uploader-base64-field");
        if (!anchor) {
          if (croppedAssetPreview) croppedAssetPreview.style.display = "none";
          if (defaultAssetImage) defaultAssetImage.style.display = "";
          if (base64InputField) base64InputField.value = "";
        }
      });
    }
  };

  this.validate_file = function (file) {
    var _this = this;
    var asset_error = document.getElementById("asset-error");
    var asset_error_details = document.getElementById("asset-error-details");

    this.mime_type = file.type;
    this.errors = [];
    if (file.size > this.maximum_file_size_mb * 1000 * 1000) {
      this.errors.push("File size is greater than " + this.maximum_file_size_mb + "MB. Please upload a smaller file.");
    }
    if (this.allowed_types.indexOf(this.mime_type) < 0) {
      this.errors.push("File type not allowed. Allowed file types: " + this.allowed_types_as_string() + ".");
    }
    if (!/^[a-zA-Z0-9_.-]+$/.test(file.name)) {
      this.errors.push("File name must not contain spaces or special characters.");
    }

    asset_error.classList.add("au-loading");
    this.asset.additional_validations(file, function (additional_errors, base64) {
      asset_error.classList.remove("au-loading");
      _this.errors = _this.errors.concat(additional_errors);

      if (_this.errors.length === 0) {
        _this.filename = file.name;
        _this.base64 = base64;
        _this.hide_overlay({ target: "manual" });
        _this.asset.post_validation_work(_this);
      } else {
        _this.errors.forEach(function (err) {
          asset_error_details.insertAdjacentHTML(
            "beforeend",
            '<p class="au-notification-text au-notification-text-warning">' + err + "</p>"
          );
        });
        asset_error.classList.add("au-visible");
      }

      // reset file input
      var select_asset = externalInputElement;
      select_asset.value = "";
    });
  };

  this.allowed_types_as_string = function () {
    var return_val;
    var regexp = /^[\w]+\/([\w]+)$/i;
    var allowed_extensions = this.allowed_types.map(function (type) {
      var result = regexp.exec(type);
      return result[1];
    });
    switch (allowed_extensions.length) {
      case 0:
        return_val = "";
        break;
      case 1:
        return_val = allowed_extensions[0];
        break;
      case 2:
        return_val = allowed_extensions[0] + " and " + allowed_extensions[1];
        break;
      default:
        return_val = "";
        for (var i = 0; i <= allowed_extensions.length - 1; i++) {
          return_val = return_val + allowed_extensions[i];
          if (i === allowed_extensions.length - 2) {
            return_val = return_val + " or ";
          } else if (i === allowed_extensions.length - 1) {
            return_val = return_val + ""; // nothing
          } else {
            return_val = return_val + ", ";
          }
        }
        break;
    }
    return return_val;
  };

  this.toggle_status = function (new_status) {
    if (new_status === "new") {
      this.status_element.classList.remove("status-existing");
      this.status_element.classList.add("status-new");
    } else if (new_status === "existing") {
      this.status_element.classList.remove("status-new");
      this.status_element.classList.add("status-existing");
    }
  };

  this.clear_asset = function (e) {
    e.preventDefault();

    this.value = 0;
    this.base64 = "";
    this.attribute_field.value = this.value;
    this.base64_field.value = this.base64;
    this.attribute_link.setAttribute("href", "");
    this.toggle_status("new");
  };

  this.display_overlay = function (e) {
    e.preventDefault();
    externalInputElement.disabled = false;

    var _this = this;
    var asset_upload_outer = document.getElementById("asset-upload-outer");
    var asset_allowed_types = document.getElementById("asset-allowed-types");
    var asset_restrictions = document.getElementById("asset-restrictions");
    var asset_drop_target = document.getElementById("asset-drop-target");
    var select_asset_link = document.getElementById("asset-select-file");
    var asset_error = document.getElementById("asset-error");
    var asset_error_details = document.getElementById("asset-error-details");

    asset_upload_outer.addEventListener("click", _this.asset_upload_outer_listener, false);
    asset_drop_target.addEventListener("drop", _this.asset_drop_target_listener, false);
    select_asset_link.addEventListener("click", _this.select_asset_link_listener, false);

    asset_error.classList.remove("au-visible");
    asset_error_details.innerHTML = "";
    asset_allowed_types.innerHTML = "(" + this.allowed_types_as_string() + " only)";
    if (this.hasOwnProperty("image_restrictions")) {
      asset_restrictions.innerHTML = this.image_restrictions + " (Max file size: " + this.maximum_file_size_mb + "MB)";
    } else {
      asset_restrictions.innerHTML = "(Maximum file size: " + this.maximum_file_size_mb + "MB)";
    }

    asset_upload_outer.classList.add("au-visible");
  };

  this.hide_overlay = function (e) {
    // enable file input after hiding overlay
    externalInputElement.disabled = false;

    if (e.target !== "manual" && !(e.target instanceof FileReader)) {
      // prevent href being loaded from link clicks only.
      e.preventDefault();
    }
    if (
      e.target === "manual" ||
      e.target instanceof FileReader ||
      e.target.id === "asset-upload-outer" ||
      e.target.id === "close-trigger"
    ) {
      var _this = this;
      var asset_upload_outer = document.getElementById("asset-upload-outer");
      var asset_allowed_types = document.getElementById("asset-allowed-types");
      var asset_restrictions = document.getElementById("asset-restrictions");
      var asset_drop_target = document.getElementById("asset-drop-target");
      var select_asset_link = document.getElementById("asset-select-file");

      asset_upload_outer.removeEventListener("click", _this.asset_upload_outer_listener, false);
      asset_drop_target.removeEventListener("drop", _this.asset_drop_target_listener, false);
      select_asset_link.removeEventListener("click", _this.select_asset_link_listener, false);

      asset_allowed_types.innerHTML = "";
      asset_restrictions.innerHTML = "";
      asset_upload_outer.classList.remove("au-visible");
    }
  };

  this.asset_file_trigger = function (e) {
    e.preventDefault();
    externalInputElement.disabled = false;
    var select_asset = externalInputElement;
    select_asset.click();
  };
}

AssetUploaderForAssetModel.asset_drag_hover = function (e) {
  "use strict";
  e.stopPropagation();
  e.preventDefault();
  var asset_drop_target = document.getElementById("asset-drop-target");
  if (e.type === "dragover") {
    asset_drop_target.classList.add("iu-hover");
  } else {
    asset_drop_target.classList.remove("iu-hover");
  }
};

AssetUploaderForAssetModel.asset_file_handler = function (e, current_instance) {
  "use strict";
  var _this = current_instance;
  // cancel event and hover styling
  AssetUploader.asset_drag_hover(e);

  // fetch FileList object
  var files = e.target.files || e.dataTransfer.files;

  // clear any existing error markup
  var asset_error = document.getElementById("asset-error");
  var asset_error_details = document.getElementById("asset-error-details");
  asset_error.classList.remove("au-visible");
  asset_error_details.innerHTML = "";

  _this.validate_file(files[0]);
};

AssetUploaderForAssetModel.read_file = function (file, callback) {
  "use strict";
  var reader = new FileReader();

  // This event is triggered each time the reading operation is successfully completed.
  reader.onload = function (event) {
    callback("ok", reader.result);
  };

  // This event is triggered each time the reading operation encounters an error.
  reader.onerror = function (event) {
    callback("ok", "");
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

CMS.asset_uploader_for_asset_model = AssetUploaderForAssetModel;
