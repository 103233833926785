CMS = CMS || {};

const select_submit_step_for_dynamic_form = () => {
  const loads = ["select_submit_step_for_dynamic_form"];

  const selectSubmitStepForDynamicForm = () => {
    const stepSelect = document.getElementById("step_selection");

    if (!stepSelect) return;

    stepSelect.addEventListener("change", function () {
      const selectedStepId = stepSelect.value;

      // Reset all is_submit_step fields
      document.querySelectorAll('input[id^="is_submit_step_"]').forEach((input) => {
        input.value = false;
      });

      // Set the selected step's is_submit_step field to true
      if (selectedStepId) {
        document.getElementById(`is_submit_step_${selectedStepId}`).value = true;
      }
    });
  };

  return {
    loads: loads,
    select_submit_step_for_dynamic_form: selectSubmitStepForDynamicForm,
  };
};

CMS.dynamic_form_steps_section = select_submit_step_for_dynamic_form();
