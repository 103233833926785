CMS = CMS || {};

const homeStockSearchConfigForm = () => {
  const loads = ["handle_stock_search_form_on_load"];

  const handleStockSearchFormOnLoad = () => {
    const stockSearchConfigForm = document.querySelector("#js-home-stock-search-config-form");

    if (!stockSearchConfigForm) return false;

    const stockSearchTypeSelector = stockSearchConfigForm.querySelector(
      "select#home_stock_search_config_stock_search_type"
    );

    if (stockSearchTypeSelector) {
      handleStockSearchTypeSelector(stockSearchTypeSelector, stockSearchConfigForm);

      stockSearchTypeSelector.addEventListener("change", async () => {
        handleStockSearchTypeSelector(stockSearchTypeSelector, stockSearchConfigForm);
      });
    }
  };

  const handleStockSearchTypeSelector = (stockSearchTypeSelector, stockSearchConfigForm) => {
    const stockSearchConfigFormImage = stockSearchConfigForm.querySelector("#js-home-stock-search-config-image");
    if (!stockSearchConfigFormImage) return false;

    if (stockSearchTypeSelector.value === "background_image") {
      stockSearchConfigFormImage.style = "display: block;";
    } else {
      stockSearchConfigFormImage.style = "display: none;";
    }
  };

  return {
    loads: loads,
    handle_stock_search_form_on_load: handleStockSearchFormOnLoad,
  };
};

CMS.homeStockSearchConfigForm = homeStockSearchConfigForm();
