CMS = CMS || {};

const loading = () => {
  const loads = ["load"];

  /**
   * fetches the submit button and add click event listener to it.
   */
  const load = () => {
    const submitBtn = document.querySelector("[data-loader='true']");
    if (null != submitBtn) submitBtn.addEventListener("click", showSpinner);
  };

  /**
   * calculate the height of the html page and assign it to the height of the loader.
   * then it will show the loader component.
   */
  const showSpinner = () => {
    const body = document.body;
    const html = document.documentElement; //documentElement is the html tag.

    //get all height values found on body, or documentElement, and use the highest one.
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const spinner = document.querySelector(".js-loader");
    spinner.style.height = `${height}px`;
    spinner.classList.remove("hidden");
  };

  return {
    loads: loads,
    load: load
  };
};

CMS.loading = loading();
