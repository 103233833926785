CMS = CMS || {};

const dealerLocatorForm = () => {
  const loads = ["handle_form_on_load"];

  const handleFormOnLoad = () => {
    const ctaForm = document.querySelector("#js-dealer_locator_cta_section");
    if (!ctaForm) return false;

    const ctaTypeRadioButtons = ctaForm.querySelectorAll(".js-url-type-clicker");

    handleCtaTypeRadioButton(ctaForm);

    ctaTypeRadioButtons.forEach((radioButton) => {
      radioButton.addEventListener("click", async () => {
        handleCtaTypeRadioButton(ctaForm);
      });
    });
  };

  const handleSelect = (dealerLocatorForm, selectId, disabled, selectedValue = "") => {
    const selectElement = dealerLocatorForm.querySelector(`select#${selectId}`);

    if (!selectElement) return;

    const hiddenInput = dealerLocatorForm.querySelector(`input#${selectId}_hidden`);

    if (disabled) selectElement.value = selectedValue;
    selectElement.disabled = disabled;
    if (hiddenInput) {
      hiddenInput.value = selectedValue;
      hiddenInput.disabled = !disabled;
    }
  };

  // clear all classes first then add one class
  const clearAllThenAddClass = (theTargetElement, theClass) => {
    if (!theTargetElement.classList.contains(theClass)) {
      theTargetElement.className = "";
      theTargetElement.classList.add(theClass);
      return;
    }
  };

  const handleCtaTypeRadioButton = (ctaForm) => {
    const checkedValue = ctaForm.querySelector("input.js-url-type-clicker:checked").value;
    var url_input = ctaForm.querySelector("input#dealer_locator_cta_link_url");
    const formFieldset = ctaForm.querySelector("fieldset");

    switch (checkedValue) {
      case "form":
        url_input.value = ""; // Remove the value from the External and Internal Links fields so graphql returns empty string when Form is selected
        clearAllThenAddClass(formFieldset, "form-cta-selected");
        return;
      case "dynamic_form":
        clearAllThenAddClass(formFieldset, "dynamic-form-selected");
        return;
      case "marketo_form":
        formFieldset.querySelector("input.js-marketo-text").placeholder = "e.g. 1478";
        clearAllThenAddClass(formFieldset, "marketo-form-selected");
        return;
      case "internal_link":
        formFieldset.querySelector("label#link").innerHTML = "Internal Link<sup class='imf-mandatory'>*</sup>";
        url_input.placeholder = "e.g. /new-cars?vehicleType=New";
        handleSelect(formFieldset, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "internal-url-selected");
        return;
      case "external_link":
        formFieldset.querySelector("label#link").innerHTML = "External Link<sup class='imf-mandatory'>*</sup>";
        url_input.placeholder = "e.g. https://www.somewebsite.com.au/page";
        handleSelect(formFieldset, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "external-url-selected");
        return;
      case "dealer_link":
        url_input.value = ""; // Remove the value from the External and Internal Links fields so graphql returns empty string when Dealer Link is selected
        clearAllThenAddClass(formFieldset, "dealer-link-selected");
        return;
    }
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.dealerLocatorForm = dealerLocatorForm();
