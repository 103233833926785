CMS = CMS || {};

const radio_toggler = () => {
  const loads = [];

  // enable or disable the input element in the radio buttons component
  /**
   *
   * @param {NodeList} triggerers : all the radiobuttons in the component
   * @param {Node} inputElement : selector of the radiobutton that enables the input element in the component
   */
  const toggleNew = (triggerers, inputElement) => {
    triggerers.forEach(triggerer => {
      if (triggerer !== null) {
        triggerer.addEventListener("click", () => {
          if (triggerer.classList.contains("js-enable-new-entry")) {
            inputElement.disabled = false;
            inputElement.focus();
          } else {
            inputElement.disabled = true;
          }
        });
      }
    });
  };

  return {
    loads: loads,
    toggle_input: toggleNew
  };
};

const radio_select_option_toggler = () => {
  const loads = [];

  // Used specifically for specials to enable or disable each radio button's dedicated select option,
  // to edit the association between a dealership, group or brand special and a vehicle model or trim.
  /**
   *
   * @param {NodeList} triggerers : all the radio buttons in the component
   * @param {Node} selectElements : all select options that'll be triggered by dedicated radio buttons
   */
  const toggleSelectOption = (triggerers, selectElements) => {
    triggerers.forEach(triggerer => {
      if (triggerer !== null) {
        triggerer.addEventListener("click", () => {
          // Disable all select options
          if (selectElements !== null) {
            selectElements.forEach(selectElement => {
              selectElement.disabled = true;
            });
          }
          // Show radio's corresponding select
          var radioOuterParentElement = triggerer.closest(".js-edit-vehiclable-wrapper");
          var RadiosAvailableSelectOption = radioOuterParentElement.querySelector(".js-select-vehiclable");
          if (RadiosAvailableSelectOption !== null) {
            RadiosAvailableSelectOption.disabled = false;
          }
        });
      }
    });
  };

  return {
    loads: loads,
    toggle_select_option: toggleSelectOption
  };
};

CMS.radio_toggler = radio_toggler();
CMS.radio_select_option_toggler = radio_select_option_toggler();

