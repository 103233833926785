import List from "list.js";

CMS = CMS || {};

const search_list = () => {
  const loads = [];

  /**
   * Takes a nodelist of the list container and filtering options object and create a searchable list for each container.
   * @param {NodeList} containers
   * @param {Object} filtering_options
   */
  const createSearchingLists = (containers, filtering_options) => {
    containers.forEach((container) => {
      createSearchingList(container, filtering_options);
    });
  };

  /**
   * Takes a list container node and filtering options object and create a searchable list for that container.
   * @param {Node} container
   * @param {Object} filtering_options
   */
  const createSearchingList = (container, filtering_options) => {
    const userList = new List(container.id, filtering_options);
    addSearchListener(userList, `.${filtering_options.searchClass}`);

    userList.on("searchComplete", function () {
      handleListItemsAfterSearch(userList);
    });
  };

  const addSearchListener = (list, selector) => {
    const searchElement = document.querySelector(selector);

    if (!searchElement) return;

    searchElement.addEventListener("keyup", () => {
      list.search(searchElement.value);
      disableSorting(searchElement.value);
    });
  };

  // disable sorting when the filter is in action
  const disableSorting = (input) => {
    const sortableListsItems = document.querySelectorAll("[data-sort_check='sort']");
    if (input === "") {
      sortableListsItems.forEach((item) => {
        item.classList.add("sortable-handle");
      });
    } else {
      sortableListsItems.forEach((item) => {
        item.classList.remove("sortable-handle");
      });
    }
  };

  const handleListItemsAfterSearch = (list) => {
    for (var i = 0, listSize = list.items.length; i < listSize; i++) {
      if (list.items[i].found || list.items[i].visible()) {
        list.items[i].elm.classList.remove("hidden");
      } else {
        list.items[i].show();
        list.items[i].elm.classList.add("hidden");
      }
    }
  };

  return {
    loads: loads,
    create_searching_lists: createSearchingLists,
    create_searching_list: createSearchingList,
  };
};

CMS.search_list = search_list();
