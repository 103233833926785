CMS = CMS || {};

const colourPicker = () => {
  const loads = ["update_colour_input", "update_text_input"];

  const updateColourInput = () => {
    const colourInputFields = document.querySelectorAll(".js-colour-input-field");

    colourInputFields.forEach((colourInput) => {
      colourInput.addEventListener("input", () => {
        colourInput.nextElementSibling.value = colourInput.value;
      });
    });
  };

  const updateTextInput = () => {
    const colourTextFields = document.querySelectorAll(".js-text-input-field");

    colourTextFields.forEach((textInput) => {
      textInput.addEventListener("input", () => {
        textInput.previousElementSibling.value = textInput.value;
      });
    });
  };

  return {
    loads: loads,
    update_colour_input: updateColourInput,
    update_text_input: updateTextInput,
  };
};

CMS.colourPicker = colourPicker();
