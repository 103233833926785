CMS = CMS || {};

const fontTypeRadioButton = () => {
  const loads = ["add_buttons_event_listeners"];

  const addButtonsEventListeners = () => {
    const radioButtons = document.querySelectorAll(".js-font-type-clicker");

    if (null !== radioButtons) {
      radioButtons.forEach((button) => {
        button.addEventListener("click", toggleFontTypeBlock);
      });
    }
  };

  const toggleFontTypeBlock = (event) => {
    const fontOptions = document.querySelectorAll(".js-font-type-chooser");
    const radioButtonFontType = event.target.dataset.fontType;

    if (null !== radioButtonFontType) {
      fontOptions.forEach((option) => {
        const blockFontType = option.dataset.fontType;
        if (radioButtonFontType === blockFontType) {
          option.classList.remove("is-not-visible");
          option.classList.add("is-visible");
        } else {
          option.selected = false;
          option.classList.remove("is-visible");
          option.classList.add("is-not-visible");
        }
      });
    }
  };

  return {
    loads: loads,
    add_buttons_event_listeners: addButtonsEventListeners,
  };
};

CMS.font_type_radio_button = fontTypeRadioButton();
