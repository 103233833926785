const CMS = {
  // To run all the functions in the loads arrays.
  init: () => {
    const arr = Object.keys(CMS)
      .filter(key => CMS[key].hasOwnProperty("loads"))
      .map(element => {
        CMS[element].loads.map(value => {
          CMS[element][value]();
        });
      });
  }
};

export default CMS;
