/********************
 What does this do ?
*********************
1. A <select> element with many options, modifies an attribute's value for a sibling <a> element.
This value is assigned to the "href" attribute of the <a> element, when an option is selected from the <select> element.
2. See a working example on the rule groups listing page for collection for the UI.
*****************************
 How To Re-use This Function
*****************************
1. You'll need to have a parent wrapper with a class name of "js-select-button-redirect-path", which will hold 2 elements.
2. The first element is a <select>. It must have a class name of "js-select-connected-to-button"
3. The second element is an <a> tag and it should have:
   i) Two class name - "imf-btn-kill" and "js-button-connected-to-select"
   ii) A data attribute -> data-rule-form-path="rule_groups/<%= rule_group.id %>/rules/new/:param".
       Note the ":param" keyword at the end. This is essential to substitute for a value as different
       options are selected using the <select> tag.
4. A page can have multiple occurrences of this parent wrapper as well as its child elements (<select> & <a>)
   with the same class names, and it will still function as intended.
To see how this is used, simply search for the "js-select-button-redirect-path" class in the views directory.
*/

CMS = CMS || {};

const constructButtonUrlUsingSelect = () => {
  const loads = ["construct_button_url"];

  const constructButtonUrl = () => {
    var wrapperForSelectAndButton = document.querySelectorAll(".js-select-button-redirect-path");
    if (!wrapperForSelectAndButton) return;
    wrapperForSelectAndButton.forEach((wrapper) => {
      wrapper.addEventListener("change", (e) => {
        // Target select element
        let select = e.currentTarget.querySelector(".js-select-connected-to-button");
        if (!select) return;
        let selectedOption = select.value;

        // Target the button
        let button = e.currentTarget.querySelector(".js-button-connected-to-select");
        if (!button) return;
        const buttonUrl = button.dataset["ruleFormPath"];
        if (!buttonUrl) {
          console.error("A 'data-rule-form-path' attribute is missing from the <a> tag. It must include ':param'");
          return false;
        }

        // Enable/Disable button and add a path if available
        if (selectedOption == "") {
          button.removeAttribute("href");
          button.classList.add("imf-btn-disabled");
          return;
        }
        button.classList.remove("imf-btn-disabled");
        let constructedButtonUrl = buttonUrl.replace(/:param/, `${selectedOption}`);
        button.href = constructedButtonUrl;
      });
    });
  };

  return {
    loads: loads,
    construct_button_url: constructButtonUrl,
  };
};

CMS.constructButtonUrlUsingSelect = constructButtonUrlUsingSelect();
