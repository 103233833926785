const { toggleClass } = require("./lib/helper");
const AddStock = require("./lib/add_stock");
const Redbook = require("./lib/redbook");

class Stock {
  constructor() {
    this.initCreateNewOriginalYard();
    this.redbook = new Redbook();
    this.initStockTypeChanges();
    new AddStock();
  }

  initCreateNewOriginalYard() {
    const yardSelectElement = document.getElementById("stock_original_yard");
    const newYardButtonElement = document.getElementById("create_new_original_yard");
    const newYardInputElement = document.getElementById("new_original_yard");
    if (!newYardButtonElement || !newYardInputElement) return;
    newYardButtonElement.addEventListener(
      "click",
      () => {
        toggleClass(newYardInputElement, "hidden");
        newYardInputElement.focus();
      },
      false
    );
    if (yardSelectElement) {
      yardSelectElement.addEventListener(
        "change",
        (event) => {
          newYardInputElement.value = event.target.options[event.target.selectedIndex].value;
        },
        false
      );
    }
  }

  initStockTypeChanges() {
    const stockTypeElement = document.getElementById("stock_stock_type");
    if (!stockTypeElement) return;
    this.togglePurifyDataButton(stockTypeElement);
    stockTypeElement.addEventListener(
      "change",
      (event) => {
        let element = event.target;
        this.togglePurifyDataButton(element);
      },
      false
    );
  }

  togglePurifyDataButton(element) {
    let purifyButtonElement = document.getElementById("purify_vehicle_data");
    let purificationSetupElement = document.getElementById("redbook-purification-selector");
    let vehicleIdentifiableElement = document.getElementById("vehicle-identifiable-attributes");
    if (element.value === "Car" || (element.options && element.options[element.selectedIndex].value === "Car")) {
      purifyButtonElement.classList.remove("hidden");
    } else {
      if (vehicleIdentifiableElement.classList.contains("hidden")) {
        vehicleIdentifiableElement.classList.remove("hidden");
      }
      if (purifyButtonElement) purifyButtonElement.classList.add("hidden");
      if (purificationSetupElement) purificationSetupElement.classList.add("hidden");
    }
  }

  stockEditPurifyData(redbookData) {
    this.redbook.purifyData(redbookData);
  }
}

module.exports = Stock;
