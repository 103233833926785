CMS = CMS || {};

const component = () => {
  const loads = ["add_prop_listener", "remove_prop_listener", "add_checkbox_listener"];

  const addPropListener = () => {
    const componentForm = document.querySelector(".js-component-form");
    if (null !== componentForm) {
      componentForm.addEventListener("click", addProp);
    }
  };
  const removePropListener = () => {
    const componentForm = document.querySelector(".js-component-form");
    if (null !== componentForm) {
      componentForm.addEventListener("click", removeProp);
    }
  };

  const addProp = event => {
    let target = null;
    if (event.target.matches(".js-add-fields")) {
      target = event.target;
    } else if (event.target.parentElement.matches(".js-add-fields")) {
      target = event.target.parentElement;
    }
    if (null !== target) {
      const parent = document.querySelector(".js-props-list");
      const time = new Date().getTime();
      const regexp = new RegExp(target.dataset.id, "g");
      // replacing ID of the fields set to prevent overriding in backend
      const partial = target.dataset.fields.replace(regexp, time);
      CMS.partials_injector.inject_last_inside_parent(parent, partial);
      event.preventDefault();
    }
  };
  const removeProp = event => {
    let target = null;
    if (event.target.matches(".js-remove-prop")) {
      target = event.target;
    } else if (event.target.parentElement.matches(".js-remove-prop")) {
      target = event.target.parentElement;
    }
    if (null !== target) {
      const parent = target.closest(".js-prop");
      const hiddenDestroy = parent.getElementsByClassName("js-destroy")[0];
      hiddenDestroy.value = "1";
      CMS.visibility_toggler.toggle_visibility_by_node(parent);
      event.preventDefault();
    }
  };

  const addCheckboxListener = () => {
    const columnCheckboxes = document.querySelectorAll(".js-component-column");
    const headerCheckboxes = document.querySelectorAll(".js-component-header");
    const footerCheckboxes = document.querySelectorAll(".js-component-footer");

    if (null === columnCheckboxes && null === headerCheckboxes && null === footerCheckboxes) return;

    columnCheckboxes.forEach(checkbox =>
      checkbox.addEventListener("change", () => {
        checkboxesChanges("js-component-column", ["js-component-header", "js-component-footer"]);
      })
    );
    headerCheckboxes.forEach(checkbox =>
      checkbox.addEventListener("change", () => {
        checkboxesChanges("js-component-header", ["js-component-column", "js-component-footer"]);
      })
    );

    footerCheckboxes.forEach(checkbox =>
      checkbox.addEventListener("change", () => {
        checkboxesChanges("js-component-footer", ["js-component-header", "js-component-column"]);
      })
    );
    checkboxesChanges("js-component-header", ["js-component-column", "js-component-footer"]);
    checkboxesChanges("js-component-column", ["js-component-header", "js-component-footer"]);
    checkboxesChanges("js-component-footer", ["js-component-header", "js-component-column"]);
  };

  const checkboxesChanges = (clickedCheckboxClass, toBeDisabledCheckboxClasses) => {
    let checkedFlag = false;
    const clickedCheckboxesList = document.querySelectorAll(`.${clickedCheckboxClass}`);
    clickedCheckboxesList.forEach(checkbox => {
      if (checkbox.checked) checkedFlag = true;
    });
    toBeDisabledCheckboxClasses.map(toBeDisabledCheckboxClass => {
      const toBeDisabledCheckboxesList = document.querySelectorAll(`.${toBeDisabledCheckboxClass}`);
      if (checkedFlag) {
        toBeDisabledCheckboxesList.forEach(checkbox => checkbox.setAttribute("disabled", "disabled"));
      } else {
        toBeDisabledCheckboxesList.forEach(checkbox => checkbox.removeAttribute("disabled"));
      }
    });
  };

  return {
    loads: loads,
    add_prop_listener: addPropListener,
    remove_prop_listener: removePropListener,
    add_checkbox_listener: addCheckboxListener
  };
};

CMS.component = component();
