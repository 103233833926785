/*

Counts down the number of characters remaining in a text field based on a recommended amount, defaults to 60.
Custom form builder method `text_field_counter` has already added the initial markup. Sample:

`<span class="character-counter" data-field="js-counter_70113540706940" data-max-allowed="60" data-current-length="0"></span>`

See webpacker.md for coding pattern, `register_text_field_counters` will get added to queue of functions executed on `turbolinks:load`.
For each `<span>` with a class of `character-counter` we call `initiateCounter` to add an event listener to the associated text field for 'keyup' event.

When the `keyup` event is triggered fired, the event listeners will update the CSS class applied to the text field and counter and also update the content of the counter element.
The classes applied are one of: `counter-ok`, `counter-warning` or `counter-error` depending on the length of the text field content:

- `counter-ok` < 75% of max allowed characters
- `counter-warning` between 75% and 100% of max allowed characters
- `counter-error` 100% and over of max allowed characters

*/

CMS = CMS || {};

const text_field_counter = () => {
  "use strict";
  const loads = ["register_text_field_counters"];

  const updateCounter = (currentValue, config) => {
    config.counter.classList.remove("counter-ok", "counter-warning", "counter-error");
    config.counter.classList.add(counterClass(currentValue.length, config.maxAllowed));
    config.counter.innerHTML = config.maxAllowed - currentValue.length + " characters remaining";
  };

  const updateTextField = (currentValue, config) => {
    config.textField.classList.remove("counter-ok", "counter-warning", "counter-error");
    config.textField.classList.add(counterClass(currentValue.length, config.maxAllowed));
  };

  const counterClass = (currentLength, maxAllowed) => {
    let returnVal = "counter-error";
    switch (true) {
      case currentLength < (75 / 100) * maxAllowed: // 75 % of max allowed
        returnVal = "counter-ok";
        break;
      case currentLength < maxAllowed:
        returnVal = "counter-warning";
        break;
      default:
        returnVal = "counter-error";
        break;
    }
    return returnVal;
  };

  const initiateCounter = counter => {
    const config = {
      initialLength: counter.dataset.initialLength,
      maxAllowed: counter.dataset.maxAllowed,
      counter: counter,
      textField: document.querySelector(counter.dataset.field)
    };

    updateCounter(config.textField.value, config);
    updateTextField(config.textField.value, config);

    config.textField.addEventListener(
      "keyup",
      () => {
        updateCounter(config.textField.value, config);
        updateTextField(config.textField.value, config);
      },
      false
    );
  };

  const registerTextFieldCounters = () => {
    const characterCounters = document.getElementsByClassName("character-counter");
    Array.from(characterCounters).forEach(counter => {
      initiateCounter(counter);
    });
  };

  return {
    loads: loads,
    register_text_field_counters: registerTextFieldCounters
  };
};

CMS.text_field_counter = text_field_counter();
