CMS = CMS || {};

const mediaTypeRadioButton = () => {
  const loads = ["add_buttons_event_listeners"];

  const addButtonsEventListeners = () => {
    const radioButtons = document.querySelectorAll(".js-media-type-clicker");

    if (null !== radioButtons) {
      radioButtons.forEach((button) => {
        console.log("Adding event for", button);
        button.addEventListener("click", toggleMediaTypeBlock);
      });
    }
  };

  const toggleMediaTypeBlock = (event) => {
    const assetBlocks = document.querySelectorAll(".js-media-type-chooser");
    const radioButtonAssetType = event.target.dataset.assetType;
    const radioButtonAssetName = event.target.dataset.assetName;
    const radioButtonAssetSubType = event.target.dataset.assetSubType;

    if (null !== radioButtonAssetType) {
      assetBlocks.forEach((block) => {
        const blockAssetType = block.dataset.assetType;
        const blockAssetName = block.dataset.assetName;
        const blockAssetSubType = block.dataset.assetSubType;
        if (null !== radioButtonAssetName && null !== blockAssetName) {
          if (radioButtonAssetName === blockAssetName) {
            if (radioButtonAssetType === blockAssetType || blockAssetType.split("|").includes(radioButtonAssetType)) {
              if (null !== radioButtonAssetSubType) {
                if (radioButtonAssetSubType === blockAssetSubType || blockAssetSubType == null) {
                  block.classList.remove("is-not-visible");
                  block.classList.add("is-visible");
                } else {
                  block.classList.remove("is-visible");
                  block.classList.add("is-not-visible");
                }
              } else {
                block.classList.remove("is-not-visible");
                block.classList.add("is-visible");
              }
            } else {
              block.classList.remove("is-visible");
              block.classList.add("is-not-visible");
            }
          }
        } else {
          console.log("radioButtonAssetType", radioButtonAssetType);
          console.log("blockAssetType", blockAssetType);
          if (radioButtonAssetType === blockAssetType || blockAssetType.split("|").includes(radioButtonAssetType)) {
            block.classList.remove("is-not-visible");
            block.classList.add("is-visible");
          } else {
            block.classList.remove("is-visible");
            block.classList.add("is-not-visible");
          }
        }
      });
    }
  };

  return {
    loads: loads,
    add_buttons_event_listeners: addButtonsEventListeners,
  };
};

CMS.media_type_radio_button = mediaTypeRadioButton();
