import {
  dropHandler,
  startDragHandler,
  isDropValid,
  parseDraggedData,
  dragoverHandler
} from "./drag_drop_functionality";

CMS = CMS || {};

const componentInstanceConfigurator = () => {
  const loads = ["add_component_instance_container_listener", "add_edit_icon_clicked_listener"];

  const addComponentInstanceContainerListener = () => {
    const container = document.querySelector(".js-component-instance-configuration-container");
    if (container) {
      container.addEventListener("click", propSaveOrCancelOrCloseClickHandler);
    }
  };

  const addPropItemsContainerListener = () => {
    const containers = document.querySelectorAll(".js-prop-items-activator");
    if (null !== containers) {
      [...containers].map(container => {
        container.addEventListener("dragover", propDropZoneDragoverHandler);
        container.addEventListener("drop", propDropZoneDropHandler);
      });
      [...document.querySelectorAll(".js-prop-items-draggable-container")].map(item => {
        item.addEventListener("dragstart", propItemDragstartHandler);
      });
    }
  };

  const propItemDragstartHandler = event => {
    startDragHandler(event);
  };

  const propDropZoneDragoverHandler = event => {
    dragoverHandler(event);
  };

  const propDropZoneDropHandler = async event => {
    event.preventDefault();
    const draggedData = event.dataTransfer.getData("text/html");
    if (isDropValid(event, draggedData)) {
      const object = parseDraggedData(draggedData);
      const list = event.target.closest(".js-drop-zone").querySelector(".js-dropped-list");
      let tempPosition = 0;
      if (list.children.length > 0) {
        tempPosition = JSON.parse(list.children[list.children.length - 1].dataset.sorting).position;
      }
      const position = tempPosition >= list.children.length ? tempPosition + 1 : list.children.length;
      const data = {
        component_components_instance: {
          props_values_instance: {
            prop_id: object.dataset.propId,
            record_id: object.dataset.recordId,
            position: position
          }
        }
      };
      dropHandler(list.dataset.updateUrl, data, "PATCH", list.children);
    }
  };

  const propSaveOrCancelOrCloseClickHandler = event => {
    if (event.target.classList.contains("js-close-button") || event.target.classList.contains("js-cancel-button")) {
      event.target.closest(".js-component-instance-configuration-container").firstChild.remove();
      const parent = document.getElementById("js-component-instance-configuration-overlay");
      if (parent.classList.contains("active") === true) {
        parent.classList.remove("active");
      }
    }
  };

  const toggleDraggingAll = itemClass => {
    const item = document.querySelector(`.${itemClass}`);
    const parent = item.closest(".js-prop-items-draggable-container");
    const draggableList = parent.querySelectorAll(".js-draggable-item");
    Array.from(draggableList).map(item => {
      toggleDragging(item.className);
    });
  };

  const toggleDragging = itemClass => {
    const item = document.getElementsByClassName(itemClass)[0];
    item.setAttribute("draggable", item.getAttribute("draggable") === "true" ? false : true);
    item.classList.toggle("disabled");
  };

  const addCssClassInputListener = () => {
    const input = document.querySelector(".js-css-class-input");
    if (null == input) return;

    const originalValue = null !== input ? input.value : "";
    input.addEventListener("focus", event => {
      const parent = event.target.closest(".js-css-class");
      const buttonsContainer = parent.querySelector(".js-form-buttons-container");
      buttonsContainer.classList.remove("hidden");
    });
    const button = document.querySelector(".js-css-class-cancel-button");
    button.addEventListener("click", event => {
      input.value = originalValue;
      const parent = event.target.closest(".js-css-class");
      const buttonsContainer = parent.querySelector(".js-form-buttons-container");
      buttonsContainer.classList.add("hidden");
    });
  };

  const addEditIconClickedEventListener = () => {
    const items = document.querySelectorAll(".js-item-edit-clicked");
    // double !(not) operator converts the nonboolean to its inverted boolean representation.
    // means you get true instead of truthy value and false instead of falsy value
    if (items) {
      items.forEach(element => {
        element.querySelector(".js-edit-icon-clicked").addEventListener("click", editIconClicked);
      });
    }
  };

  const editIconClicked = event => {
    const target = event.target.closest(".js-item-edit-clicked");
    if (target) {
      const parent = target.closest(".js-edit-item-column-clicked");
      const otherPossibleTargets = document.querySelectorAll(".js-item-edit-clicked");
      otherPossibleTargets.forEach(element => {
        element.classList.remove("editing-item");
      });
      const otherPossibleParents = document.querySelectorAll(".js-edit-item-column-clicked");
      otherPossibleParents.forEach(element => {
        element.classList.remove("focused-column");
      });
      target.classList.add("editing-item");
      parent.classList.add("focused-column");
    }
  };

  return {
    loads: loads,
    add_props_items_listeners: addPropItemsContainerListener,
    toggle_dragging_all: toggleDraggingAll,
    toggle_dragging: toggleDragging,
    add_css_class_input_listener: addCssClassInputListener,
    add_component_instance_container_listener: addComponentInstanceContainerListener,
    add_edit_icon_clicked_listener: addEditIconClickedEventListener
  };
};

CMS.componentInstanceConfigurator = componentInstanceConfigurator();
