CMS = CMS || {};

// On a collections' rule group page, the opacity of a rule groups' rules will be reduced if rule group's toggle is inactive
const toggleRuleGroupRules = () => {
  // populating the loads array with the function required to run in the "turbolinks:load" listener.
  const loads = ["toggle_rule_group"];

  const toggleRuleGroupClass = () => {
    document.querySelectorAll(".js-rule-group-wrapper").forEach((ruleGroup) => {
      const rules = ruleGroup.querySelector(".js-rule-groups-rules");

      const ruleGroupToggleStatus = ruleGroup.querySelector(".js-rule-group-toggle-status");

      // This allows the user to toggle the inactive class of a rule group

      ruleGroupToggleStatus.addEventListener("ajax:success", (event) => {
        const ruleGroupStatus = event.detail[0].active;

        if (ruleGroupStatus === true) {
          rules.classList.remove("rule-group-rules-inactive");
        } else {
          rules.classList.add("rule-group-rules-inactive");
        }
      });
    });
  };
  // exposing public methods and attributes
  return {
    loads: loads, // loads should be public all the time.
    toggle_rule_group: toggleRuleGroupClass, // key should be the same as in loads array
  };
};

CMS.toggleRuleGroupRules = toggleRuleGroupRules();
