CMS = CMS || {};

const specials = () => {
  const loads = ["toggle_vehiclable_select_option"];

  const toggleVehiclableSelectOption = () => {
    const triggerers = document.querySelectorAll(".js-radio-toggle-vehiclable");
    const selectElements = document.querySelectorAll(".js-select-vehiclable");
    CMS.radio_select_option_toggler.toggle_select_option(triggerers, selectElements);
  };

  return {
    loads: loads,
    toggle_vehiclable_select_option: toggleVehiclableSelectOption,
  };
};

CMS.specials = specials();
