CMS = CMS || {};

const configForm = () => {
  const loads = ["handle_form_on_load"];

  const handleFormOnLoad = () => {
    const notificationEmailRecipientButtons = document.querySelectorAll(".js-notification-email-recipient-clicker");
    const notificationEmailTemplateButtons = document.querySelectorAll(".js-notification-email-template-clicker");
    const autoResponseEmailTemplateButtons = document.querySelectorAll(".js-auto-response-email-template-clicker");
    const notificationEmailCheckbox = document.querySelector("#config_form_dealership_email");
    const autoResponseEmailCheckbox = document.querySelector("#config_form_customer_email");

    const clickEvent = new Event("click");
    const singleValueSlider = document.getElementById("single_value_price_slider");
    const singleValue = document.getElementById("single_value_price");
    const lowRangeAdjustSlider = document.getElementById("low_range_adjust_slider");
    const lowRangeAdjustValue = document.getElementById("low_range_adjust");
    const highRangeAdjustSlider = document.getElementById("high_range_adjust_slider");
    const highRangeAdjustValue = document.getElementById("high_range_adjust");

    // Update the value span when the slider is moved
    if (singleValueSlider) {
      singleValueSlider.addEventListener("input", function () {
        var value = this.value;
        singleValue.textContent = value;
      });
    }

    if (lowRangeAdjustSlider) {
      lowRangeAdjustSlider.addEventListener("input", function () {
        var value = this.value;
        lowRangeAdjustValue.textContent = value;
      });
    }

    if (highRangeAdjustSlider) {
      highRangeAdjustSlider.addEventListener("input", function () {
        var value = this.value;
        highRangeAdjustValue.textContent = value;
      });
    }

    if (notificationEmailRecipientButtons) {
      notificationEmailRecipientButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", async (event) => {
          handleRadioButton(event, ".notification-email-recipient-text-section");
        });
        if (radioButton.checked) radioButton.dispatchEvent(clickEvent);
      });
    }

    if (notificationEmailTemplateButtons) {
      notificationEmailTemplateButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", async (event) => {
          handleRadioButton(event, ".notification-email-template-text-section");
        });
        if (radioButton.checked) radioButton.dispatchEvent(clickEvent);
      });
    }

    if (autoResponseEmailTemplateButtons) {
      autoResponseEmailTemplateButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", async (event) => {
          handleRadioButton(event, ".auto-response-email-template-text-section");
        });
        if (radioButton.checked) radioButton.dispatchEvent(clickEvent);
      });
    }

    if (notificationEmailCheckbox) {
      notificationEmailCheckbox.addEventListener("change", async (event) => {
        handleMainCheckbox(event, ".config-form-notification-email-section");
      });
    }

    if (autoResponseEmailCheckbox) {
      autoResponseEmailCheckbox.addEventListener("change", async (event) => {
        handleMainCheckbox(event, ".config-form-auto-response-email-section");
      });
    }
  };

  const handleRadioButton = (event, sectionClass) => {
    const targetValue = event.target.value;
    const additionalInputSection = document.querySelector(sectionClass);
    if (["specified", "sendgrid", "preferred_dealer"].includes(targetValue)) {
      additionalInputSection.classList.remove("section-hidden");
    } else {
      additionalInputSection.classList.add("section-hidden");
    }
  };

  const handleMainCheckbox = (event, sectionClass) => {
    const checkedValue = event.target.checked;
    const settingsSection = document.querySelector(sectionClass);

    if (checkedValue) {
      settingsSection.classList.remove("section-hidden");
    } else {
      settingsSection.classList.add("section-hidden");
    }
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.configForm = configForm();
