// required in rails 6
// import Rails from 'rails-ujs'

export const startDragHandler = event => {
  if (!event.target.closest(".js-draggable-item").getAttribute("draggable")) {
    return false;
  }
  event.dataTransfer.setData("text/html", event.target.closest(".js-draggable-item").outerHTML);
  event.dataTransfer.effectAllowed = "copy";
  event.dataTransfer.dropEffect = "copy";
};

export const dropHandler = async (url, data, method, list = []) => {
  try {
    const response = await fetchAjax(url, data, method);
    executeResponse(response, list);
  } catch (error) {
    alert(error);
  }
};

export const dragoverHandler = event => {
  event.preventDefault();
  if (!event.target.closest(".js-drop-zone") || event.target.closest(".disabled")) return false;

  event.dataTransfer.dropEffect = "copy";
};

export const fetchAjax = async (url = "", data = {}, method = "GET") => {
  let options = {
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: {
      Accept: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript",
      "X-CSRF-Token": Rails.csrfToken(),
      "X-Requested-With": "XMLHttpRequest"
    },
    cache: "no-cache",
    redirect: "error",
    referrer: "no-referrer"
  };
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    Object.assign(options, {
      method: method,
      headers: {
        Accept: "text/javascript, application/javascript, application/ecmascript, application/x-ecmascript",
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
  }

  const response = await fetch(url, options);
  return await response;
};

export const executeResponse = (response, list = []) => {
  return response.text().then(text => {
    //this is how rails-ujs renders the returned value from any .js.erb file
    const script = document.createElement("script");
    script.setAttribute("nonce", Rails.cspNonce());
    script.text = text;
    document.head.appendChild(script).parentNode.removeChild(script);
    reorderList(list);
  });
};

export const isDropValid = (event, draggedData) => {
  let flag = true;
  if (
    !event.target.closest(".js-drop-zone") ||
    event.target.closest(".js-drop-zone").classList.contains("disabled") ||
    draggedData === ""
  ) {
    flag = false;
  }
  return flag;
};

export const parseDraggedData = draggedData => {
  const parser = new DOMParser();
  return parser.parseFromString(draggedData, "text/html").body.firstElementChild;
};

const reorderList = list => {
  [...list].forEach((item, index) => {
    let tempSorting = JSON.parse(item.dataset.sorting);
    if (index !== tempSorting.index) {
      tempSorting.index = index;
    }
    item.dataset.sorting = JSON.stringify(tempSorting);
  });
};
