CMS = CMS || {};

const asset_uploader = () => {
  const loads = ["load_assets", "add_remove_listeners"];
  const asset_type = {
    "image/jpeg": "image",
    "image/png": "image",
    "image/webp": "image",
    "image/gif": "image",
    "application/pdf": "pdf",
    "image/svg+xml": "svg",
    "video/webm": "video",
    "video/mp4": "video",
  };
  const error_messages = {
    image_wrong_type: "Accepted image formats are .jpeg, .jpg, .png, .gif, .webp ONLY",
    pdf_wrong_type: "Accepted file format is .pdf ONLY",
    video_wrong_type: "Accepted video formats are .mp4 and .webm ONLY",
  };
  let error_type = "";

  /**
   *  will run first to attach listeners to input type="file" DOM elements
   */
  const loadAssets = () => {
    const uploaders = document.querySelectorAll(".js-uploader");

    uploaders.forEach((uploader) => {
      if (uploader.dataset.accept == "image" && uploader.dataset.enableCropper === "true") {
        const attribute = uploader.dataset.accept;
        const width = parseInt(uploader.dataset.width);
        const height = parseInt(uploader.dataset.height);
        const upload_limit = parseInt(uploader.dataset.limit);
        const image_asset = new CMS.image_uploader_for_asset_model(width, height, upload_limit);
        const image_upload = new CMS.asset_uploader_for_asset_model(attribute, uploader.value, image_asset, uploader);
        image_upload.attach_events();
      }
    });

    uploaders.forEach((uploader) => {
      if (uploader.dataset.accept == "image" && uploader.dataset.enableCropper === "true") {
        uploader.addEventListener(
          "click",
          (event) => {
            event.target.blur();
          },
          false
        );
      } else {
        uploader.addEventListener(
          "change",
          (event) => {
            event.target.blur();
            fetchFiles(event.target);
          },
          false
        );
      }
    });
  };

  const addRemoveBtnsListeners = () => {
    const removeBtns = document.querySelectorAll(".js-asset-remove-btn");
    removeBtns.forEach((btn) => {
      btn.addEventListener("click", (event) => {
        removeFile(event.target);
      });
    });
  };

  const fetchFiles = (element) => {
    const files = element.files;
    //spreading files into array to traverse files. FilesList doesn't support forEach because it is not array.
    [...files].map((file) => {
      fetchFile(element, file);
    });
  };

  const fetchFile = (element, file) => {
    if (isFileValid(element, file) && isValidFileName(file)) {
      parseFile(element, file);
      hideError(element.parentElement);
    } else {
      showError(element);
      element.value = "";
      if (isOriginalEmpty(element)) {
        returnOriginalStatus(element);
      }
    }
  };

  const isOriginalEmpty = (element) => {
    const parent = element.parentElement;
    const id = parent.querySelector(".js-hidden-remove");
    if (id.value == "") return true;
    return false;
  };

  const returnOriginalStatus = (element) => {
    const parent = element.parentElement;
    const grandParent = parent.parentElement;
    const removeDiv = grandParent.nextElementSibling;
    removeImage(parent);
    toggleButtons(parent, removeDiv, "remove");
  };

  /**
   * prepare the fields of the asset uploader to be ready for uploading.
   * @param {File} file file selected by the input type="file" element.
   */
  const parseFile = (element, file) => {
    const parentElement = element.parentElement;
    //adding data to hidden inputs
    if (null != parentElement) {
      let hidden_name = parentElement.querySelector(".js-hidden-name");
      hidden_name.value = file.name;
      let hidden_cat_name = parentElement.querySelector(".js-hidden-cat-name");
      hidden_cat_name.value = hidden_cat_name.dataset.catName;
      let hidden_cat_id = parentElement.querySelector(".js-hidden-cat-id");
      hidden_cat_id.value = hidden_cat_id.dataset.categoryId;
    }
    populateAsset(parentElement, file);
  };

  /**
   * populate the uploader component with either an image of the file(being uploaded)
   * or if it can't upload an image of the file.
   * @param {Element} parent parent DOM element of the file uploader
   * @param {File} file file being uploaded
   */
  const populateAsset = (parent, file) => {
    const svg = parent.querySelector(".js-asset-img-svg");
    let anchor = parent.querySelector(".js-anchor");
    if (asset_type[file.type] == "image") {
      const filledATag = populateImage(anchor, file);
      showAsset(parent, filledATag, svg);
    } else if (asset_type[file.type] == "pdf") {
      showAsset(parent, populateNonImage(anchor, file, "svg-pdf"), svg);
    } else if (asset_type[file.type] == "svg") {
      showAsset(parent, populateNonImage(anchor, file, "svg-svg"), svg);
    } else {
      showAsset(parent, populateNonImage(anchor, file, "svg-video-computer"), svg);
    }
  };

  /**
   * returns either a parsed image of the file being uploaded or svg inside a(anchor) tag.
   * @param {Element} img DOM image element in the asset uploader component
   * @param {File} file file being uploaded
   */
  const populateImage = (anchor, file) => {
    if (null == anchor) {
      anchor = document.createElement("a");
      anchor.setAttribute("target", "blank");
      anchor.classList.add("js-anchor");
    }
    const reader = readFile(file);
    if (null != reader) {
      const img = returnImageInside(anchor);
      reader.onload = (event) => {
        const result = event.target.result;
        img.src = result;
        anchor.href = `${result}`;
      };
    } else {
      anchor.append(createSvg("au-icon", "svg-image"));
      anchor.href = "#";
    }
    return anchor;
  };

  /**
   * append an image inside parent if it doesn't have one.
   * @param {Element} parent DOM Element, parent of the image
   */
  const returnImageInside = (parent) => {
    let img = parent.querySelector(".js-asset-img");
    if (null == img) {
      img = document.createElement("img");
      img.classList.add("imf-asset-img");
      img.classList.add("js-asset-img");
      parent.append(img);
    }
    return img;
  };

  /**
   * returns svg for non image (pdf and video) assets being uploaded
   * @param {File} file file being uploaded
   * @param {String} icon svg name with svg- appended to it
   */
  const populateNonImage = (anchor, file, icon) => {
    if (null == anchor) {
      anchor = document.createElement("a");
      anchor.setAttribute("target", "blank");
      anchor.classList.add("js-anchor");
    }
    const reader = readFile(file);
    if (null != reader) {
      reader.onload = (event) => {
        anchor.href = event.target.result;
      };
    } else {
      anchor.href = "#";
    }
    anchor.innerHTML = "";
    anchor.append(createSvg("au-icon", icon));
    return anchor;
  };

  const readFile = (file) => {
    if (typeof FileReader != "undefined") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    }
    return null;
  };
  /**
   * attach the asset element(image or svg DOM element) to the parent element to make it visible on the page.
   * @param {Element} parent DOM element
   * @param {Element} asset DOM element
   * @param {Element} svg DOM element
   */
  const showAsset = (parent, asset, svg = null) => {
    if (null != svg) {
      parent.replaceChild(asset, svg);
    }
    const assetDiv = parent.parentElement;
    const removeDiv = assetDiv.nextElementSibling;
    toggleButtons(assetDiv, removeDiv, "show");
  };

  /**
   * returns svg DOM element
   * @param {String} svgClasses string of desired classes of the returned svg DOM element
   * @param {String} icon string to be used in the use element.
   * It should be the whole symbol id from the svg-defs.svg file
   */
  const createSvg = (svgClasses, icon) => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("class", svgClasses);
    const use = document.createElementNS("http://www.w3.org/2000/svg", "use");
    use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `#${icon}`);
    svg.appendChild(use);
    return svg;
  };

  /**
   * toggles buttons of asset uploader depending on the passed status
   * @param {Element} parent DOM element, parent of the input file upload element
   * @param {String} status determine which buttons going to be displayed.
   */
  const toggleButtons = (assetDiv, removeDiv, status = "remove") => {
    const uploadBtn = assetDiv.querySelector(".js-asset-upload-btn");
    const replaceBtn = assetDiv.querySelector(".js-asset-replace-btn");
    const removeBtnDiv = removeDiv.querySelector(".js-asset-remove-btn").parentElement;
    if ("show" == status) {
      uploadBtn.classList.add("hidden");
      replaceBtn.classList.remove("hidden");
      removeBtnDiv.classList.remove("hidden");
    } else {
      uploadBtn.classList.remove("hidden");
      replaceBtn.classList.add("hidden");
      removeBtnDiv.classList.add("hidden");
    }
  };

  /**
   * removes the file from display and toggle buttons of asset uploader
   * @param {Element} element input file type DOM element
   */
  const removeFile = (element) => {
    let removeDiv = element.parentElement;
    while (!removeDiv.classList.contains("js-au-asset-remove-wrapper")) {
      removeDiv = removeDiv.parentElement;
    }
    const sibling = removeDiv.previousElementSibling;
    const assetDiv = sibling.querySelector(".js-asset-uploader");
    emptyRelatedFields(assetDiv);
    removeImage(assetDiv);
    toggleButtons(assetDiv, removeDiv, "remove");
    hideError(assetDiv);
  };

  /**
   * empties all the hidden fields related to the asset being uploaded
   * @param {Element} parent DOM element, parent of the input file upload element
   */
  const emptyRelatedFields = (parent) => {
    let hidden_id = parent.querySelector(".js-hidden-remove");
    hidden_id.value = "";
    let hidden_name = parent.querySelector(".js-hidden-name");
    hidden_name.value = "";
    let hidden_cat_name = parent.querySelector(".js-hidden-cat-name");
    hidden_cat_name.value = "";
    let hidden_cat_id = parent.querySelector(".js-hidden-cat-id");
    hidden_cat_id.value = "";
    let uploader = parent.querySelector(".js-uploader");
    uploader.value = "";
  };

  /**
   *  remove the image or svg from the front end and replace it with the empty field svg
   * @param {*} parent DOM element, parent of the input file upload element
   */
  const removeImage = (parent) => {
    const anchor = parent.querySelector(".js-anchor");
    if (null != anchor) {
      const emptySvg = createSvg("au-icon-no-asset js-asset-img-svg", "svg-no-asset");
      parent.replaceChild(emptySvg, anchor);
    }
  };
  /**
   * fills the error message and shows the error section in the asset uploader component
   * @param {Node} element the input type="file" DOM element
   * @param {Node} file one of the selected files in the input type="file" DOM element
   */
  const showError = (element) => {
    const parent = element.parentElement;
    const error_element = parent.nextElementSibling;
    const error = error_element.querySelector(".js-error-message");
    if (error_type == "size") {
      error.innerText = "File size should not be more than " + parseInt(element.dataset.limit) + " MB.";
    } else if (error_type == "file_name") {
      error.innerText = "File name must not contain any spaces or special characters.";
    } else error.innerText = error_messages[`${element.dataset.accept}_${error_type}`];
    error_element.classList.remove("hidden");
  };

  const hideError = (parent) => {
    const error_element = parent.nextElementSibling;
    error_element.classList.add("hidden");
  };

  /**
   * check for both size and type of the file.
   * @param {File} file one of the selected files in the input type="file" DOM element
   */
  const isFileValid = (element, file) => {
    if (isValidType(element, file)) {
      if (isValidSize(file, parseInt(element.dataset.limit))) {
        return true;
      }
      error_type = "size";
      return false;
    }
    error_type = "wrong_type";
    return false;
  };

  const isValidType = (element, file) => {
    if (
      (asset_type[file.type] == "image" && element.dataset.accept == "image") ||
      (asset_type[file.type] == "pdf" && element.dataset.accept == "pdf") ||
      (asset_type[file.type] == "video" && element.dataset.accept == "video") ||
      (asset_type[file.type] == "svg" && element.dataset.accept == "svg")
    )
      return true;
    return false;
  };

  const isValidFileName = (file) => {
    error_type = "file_name";
    return /^[a-zA-Z0-9_.-]+$/.test(file.name);
  };

  const isValidSize = (file, upload_limit) => {
    if (file.size <= upload_limit * 1024 * 1024) return true;
    return false;
  };

  return {
    loads: loads,
    load_assets: loadAssets,
    add_remove_listeners: addRemoveBtnsListeners,
  };
};

CMS.asset_uploader = asset_uploader();
