const uiPatternRadioButton = () => {
  const loads = ["add_buttons_event_listeners"];

  const addButtonsEventListeners = () => {
    const radioButtons = document.querySelectorAll(".js-ui-pattern-clicker");

    if (null !== radioButtons) {
      radioButtons.forEach((button) => {
        button.addEventListener("click", toggleMediaTypeBlock);
      });
    }

    // Trigger click event on the initially selected UI pattern
    const initiallySelectedPattern = document.querySelector(".js-ui-pattern-clicker:checked");
    if (initiallySelectedPattern !== null) {
      toggleMediaTypeBlock({ target: initiallySelectedPattern }, true);
    }
  };

  const toggleMediaTypeBlock = (event, initialLoad) => {
    const selectedUiPatternId = event.target.dataset.assetId;

    const components = document.querySelectorAll(".js-media-type-chooser");
    const assetIds = [];

    const fallbackMessage = document.querySelector(".js-fallback-message");

    if (selectedUiPatternId == undefined) {
      fallbackMessage.classList.add("is-not-visible");
      if (document.querySelector(".js-media-type-chooser:checked") && !initialLoad) {
        document.querySelector(".js-media-type-chooser:checked").checked = false;
      }
    }
    components.forEach((component) => {
      const assetId = component.dataset.assetId;
      assetIds.push(assetId);
      fallbackMessage.classList.add("is-not-visible");

      if (selectedUiPatternId === component.dataset.assetId) {
        component.classList.remove("is-not-visible");
        component.classList.add("is-visible");
      } else {
        component.classList.remove("is-visible");
        component.classList.add("is-not-visible");
      }
    });

    const allUiPatternIdsFromAvaliableComponents = [...new Set(assetIds)];

    if (!allUiPatternIdsFromAvaliableComponents.includes(selectedUiPatternId)) {
      fallbackMessage.classList.remove("is-not-visible");
      fallbackMessage.classList.add("is-visible");
    }
  };

  return {
    loads: loads,
    add_buttons_event_listeners: addButtonsEventListeners,
  };
};

CMS.ui_pattern_radio_button = uiPatternRadioButton();
