CMS = CMS || {};

const vehicleFeatureForm = () => {
  const loads = ["handle_form_on_load"];

  const handleFormOnLoad = () => {
    const uiPatternSelector = document.querySelector("#vehicle_vehicle_feature_ui_pattern_id");
    if (!uiPatternSelector) return false;

    const uiPatternNote = document.querySelector("#js-ui-pattern-note");
    const uiPatternName = document.querySelector("#js-ui-pattern-name");

    handleUiPatternSelector(uiPatternSelector, uiPatternNote, uiPatternName);

    uiPatternSelector.addEventListener("change", async () => {
      handleUiPatternSelector(uiPatternSelector, uiPatternNote, uiPatternName);
    });
  };

  const handleUiPatternSelector = (uiPatternSelector, uiPatternNote, uiPatternName) => {
    var ignores = uiPatternSelector.options[uiPatternSelector.selectedIndex].dataset.ignores;
    if (ignores === "true") {
      uiPatternNote.style.display = "";
      uiPatternName.innerHTML = uiPatternSelector.options[uiPatternSelector.selectedIndex].text;
    } else {
      uiPatternNote.style.display = "none";
    }
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.vehicleFeatureForm = vehicleFeatureForm();
