CMS = CMS || {};

const vehicle_model = () => {
  const loads = [
    "toggle_options_visibility",
    "toggle_new_section",
    "toggle_colour_category_radios",
    "toggle_colour_set_radios",
  ];

  // in the models summary tab to show or hide the extra elements of the form
  const toggleOptionsVisibility = () => {
    const trigger_element = document.querySelector(".js-toggle-view");
    if (trigger_element !== null) {
      trigger_element.addEventListener("click", e => {
        CMS.visibility_toggler.toggle_visibility(e);
        false;
      });
    }
  };

  const toggleSectionsRadios = () => {
    const triggerers = document.querySelectorAll(".js-radio-toggle-section");
    const newInputSection = document.querySelector(".js-input-section");
    CMS.radio_toggler.toggle_input(triggerers, newInputSection);
  };

  const toggleColourCategoryRadios = () => {
    const triggerers = document.querySelectorAll(".js-radio-toggle-category");
    const newInputSection = document.querySelector(".js-input-category");
    CMS.radio_toggler.toggle_input(triggerers, newInputSection);
  };

  const toggleColourSetRadios = () => {
    const triggerers = document.querySelectorAll(".js-radio-toggle-set");
    const newInputSection = document.querySelector(".js-input-set");
    CMS.radio_toggler.toggle_input(triggerers, newInputSection);
  };

  return {
    loads: loads,
    toggle_options_visibility: toggleOptionsVisibility,
    toggle_new_section: toggleSectionsRadios,
    toggle_colour_category_radios: toggleColourCategoryRadios,
    toggle_colour_set_radios: toggleColourSetRadios,
  };
};

CMS.vehicle_model = vehicle_model();
