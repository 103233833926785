CMS = CMS || {};

// Creating the desired object using the revealing module pattern
const dealership = () => {
  // populting the loads array with the function required to run in the "turbolinks:load" listener.
  const loads = ["toggle_dealership_options"];

  const toggle_dealership_options = () => {
    const trigger_element = document.querySelector(".js-dealer-toggle-view");
    if (trigger_element !== null) {
      trigger_element.addEventListener("click", e => {
        CMS.visibility_toggler.toggle_visibility(e);
        false;
      });
    }
  };

  // exposing public mehtods and attributes
  return {
    loads: loads, // loads should be public all the time.
    toggle_dealership_options: toggle_dealership_options // key should be the same as in loads array
  };
};

// attaching the dealership object to the CMS object
CMS.dealership = dealership();
