CMS = CMS || {};

function ImageUploaderForAssetModel(init_category_width, init_category_height, upload_limit) {
  "use strict";
  this.category_width = init_category_width;
  this.category_height = init_category_height;
  this.img = new Image();
  this.allowed_types = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
  this.maximum_file_size_mb = upload_limit;

  this.additional_validations = function (file, callback) {
    var additional_errors = [];
    var _this = this;

    if (file.size > this.maximum_file_size_mb * 1024 * 1024) {
      // file should not be read - too large
      callback(additional_errors, "");
      return;
    }

    if (this.allowed_types.indexOf(file.type) < 0) {
      // file should not be read - not an acceptable type
      callback(additional_errors, "");
      return;
    }

    if (this.category_width === 0) {
      additional_errors.push("Image Category width not configured correctly.");
    }
    if (this.category_height === 0) {
      additional_errors.push("Image Category height not configured correctly.");
    }

    AssetUploader.read_file(file, function (status, base64) {
      if (status === "error") {
        additional_errors.push("Sorry, we were unable to read your image. Please try again.");
        callback(additional_errors, base64);
        return;
      } else {
        _this.img.onload = function () {
          // access image dimensions
          if (_this.img.width < _this.category_width) {
            additional_errors.push(
              "Image is too narrow (" + _this.img.width + "px, should be at least " + _this.category_width + "px)"
            );
          }
          if (_this.img.height < _this.category_height) {
            additional_errors.push(
              "Image is too short (" + _this.img.height + "px, should be at least " + _this.category_height + "px)"
            );
          }
          callback(additional_errors, base64);
        };
        _this.img.src = base64;
      }
    });
  };

  this.post_validation_work = function (parent) {
    this.image_cropper = new ImageCropper(
      this.img,
      this.category_width,
      this.category_height,
      parent.mime_type,
      function (cropped_base64) {
        parent.element.nextElementSibling.value = cropped_base64;
        parent.element.nextElementSibling.nextElementSibling.value = parent.filename;
        // show remove button
        const removeBtn = parent.element.parentElement.parentElement.parentElement.querySelector(
          ".js-au-asset-remove-wrapper"
        );
        if (removeBtn) removeBtn.classList.remove("hidden");
        // show replace button
        const replaceBtn = parent.element.parentElement.querySelector(".js-asset-replace-btn");
        if (replaceBtn) replaceBtn.classList.remove("hidden");
        // hide upload button
        const uploadBtn = parent.element.parentElement.querySelector(".js-asset-upload-btn");
        if (uploadBtn) uploadBtn.classList.add("hidden");
        // hide default preview image
        const defaultAssetImage = parent.element.parentElement.querySelector(".js-asset-img-svg");
        if (defaultAssetImage) {
          defaultAssetImage.style.display = "none";
        }
        // hide old asset preview if present
        const oldAssetAttachmentPreview = parent.element.parentElement.querySelector(".au-link");
        if (oldAssetAttachmentPreview) {
          oldAssetAttachmentPreview.style.display = "none";
        }
        // add cropped base64 image to preview
        const assetPreview = parent.element.parentElement.querySelector(".js-cropped-asset-preview");
        if (assetPreview) {
          assetPreview.setAttribute("src", cropped_base64);
          assetPreview.style.display = "block";
        }
        const categoryIdInput = parent.element.parentElement.querySelector(".js-hidden-cat-id");
        const categoryNameInput = parent.element.parentElement.querySelector(".js-hidden-cat-name");
        const assetIdInput = parent.element.parentElement.querySelector(".js-hidden-remove");
        const assetNameInput = parent.element.parentElement.querySelector(".js-hidden-name");

        if (categoryIdInput && categoryNameInput && assetIdInput && assetNameInput) {
          const categoryId = categoryIdInput.dataset.categoryId;
          categoryIdInput.value = categoryId;

          const categoryName = categoryNameInput.dataset.categoryName;
          categoryNameInput.value = categoryName;

          const assetId = assetIdInput.dataset.assetId;
          assetIdInput.value = assetId;

          const assetName = assetNameInput.dataset.assetName;
          assetNameInput.value = assetName;
        }
      }
    );
  };
}

CMS.image_uploader_for_asset_model = ImageUploaderForAssetModel;
