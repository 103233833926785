CMS = CMS || {};

const pageDetailsForm = () => {
  const loads = ["handle_form_on_load"];

  const handleFormOnLoad = () => {
    const detailsForm = document.querySelector("#js_content_pages_section_form");
    if (!detailsForm) return false;

    const pageTypeSelector = detailsForm.querySelector("select#page_page_type");
    const linkTypeRadioButtons = detailsForm.querySelectorAll(".js-url-type-clicker");
    const parentPickerRadioButtons = detailsForm.querySelectorAll('input[name="page[parent_page_id]"]');

    $(".js-page-layout-radio").click(function () {
      var cnfrm = confirm(
        "Warning: Changing the layout will result in the loss of content. You may want to copy content before changing layout."
      );
      if (cnfrm != true) {
        return false;
      }
    });

    if (linkTypeRadioButtons.length > 0) {
      if (detailsForm.querySelector("input.js-url-type-clicker:checked")) handleLinkTypeRadioButton(detailsForm);

      linkTypeRadioButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", async () => {
          handleLinkTypeRadioButton(detailsForm);
        });
      });
    }

    if (parentPickerRadioButtons.length > 0) {
      handleParentPickerRadioButton(detailsForm);
      parentPickerRadioButtons.forEach((radioButton) => {
        radioButton.addEventListener("click", async () => {
          handleParentPickerRadioButton(detailsForm);
        });
      });
    }

    if (pageTypeSelector) {
      handlePageTypeSelector(pageTypeSelector, detailsForm);

      pageTypeSelector.addEventListener("change", async () => {
        handlePageTypeSelector(pageTypeSelector, detailsForm);
      });
    }
  };

  // clear all classes first then add one class
  const clearAllThenAddClass = (theTargetElement, theClass) => {
    if (!theTargetElement.classList.contains(theClass)) {
      theTargetElement.className = "";
      theTargetElement.classList.add(theClass);
      return;
    }
  };

  const handleLinkTypeRadioButton = (detailsForm) => {
    const checkedValue = detailsForm.querySelector("input.js-url-type-clicker:checked").value;
    const formFieldset = detailsForm.querySelector("fieldset");

    switch (checkedValue) {
      case "cta":
        clearAllThenAddClass(formFieldset, "form-cta-selected");
        return;
      case "form":
        clearAllThenAddClass(formFieldset, "form-cta-selected");
        return;
      case "internal_link":
        detailsForm.querySelector("input.js-link-text").placeholder = "e.g. /new-cars?vehicleType=New";
        handleSelect(detailsForm, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "internal-url-selected");
        return;
      case "external_link":
        detailsForm.querySelector("input.js-link-text").placeholder = "e.g. https://www.somewebsite.com.au/page";
        handleSelect(detailsForm, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "external-url-selected");
        return;
      case "iframe_url":
        detailsForm.querySelector("input.js-link-text").placeholder = "e.g. https://www.someiframe.com.au";
        clearAllThenAddClass(formFieldset, "iframe-url-selected");
        return;
      case "dynamic_form":
        clearAllThenAddClass(formFieldset, "dynamic-form-selected");
        return;
      case "marketo_form":
        detailsForm.querySelector("input.js-marketo-text").placeholder = "e.g. 1478";
        clearAllThenAddClass(formFieldset, "marketo-form-selected");
        return;
      case "none":
        clearAllThenAddClass(formFieldset, "option-none-selected");
        return;
    }
  };

  const handleParentPickerRadioButton = (detailsForm) => {
    const checkedValue = detailsForm.querySelector('input[name="page[parent_page_id]"]:checked');
    if (checkedValue) {
      var parentValue = checkedValue.value;
      if (parentValue == "") removeClassFromDetailsForm(detailsForm, "pagetype-non-standard");
      else addClassToDetailsForm(detailsForm, "pagetype-non-standard");
    }
  };

  const showHideParentPagePicker = (detailsForm, show) => {
    const parentPicker = detailsForm.querySelector(".section-page-parent-page-picker");
    if (!parentPicker) return;

    if (!show) {
      var selectedParent = document.querySelector('input[name="page[parent_page_id]"]:checked');
      if (selectedParent) selectedParent.checked = false;
      parentPicker.style = "display:none";
    } else {
      parentPicker.style = "";
    }
  };

  const handleSelect = (detailsForm, selectId, disabled, selectedValue = "") => {
    const selectElement = detailsForm.querySelector(`select#${selectId}`);

    if (!selectElement) return;

    const hiddenInput = detailsForm.querySelector(`input#${selectId}_hidden`);

    if (disabled) selectElement.value = selectedValue;
    selectElement.disabled = disabled;
    if (hiddenInput) {
      hiddenInput.value = selectedValue;
      hiddenInput.disabled = !disabled;
    }
  };

  const handlePageTypeSelector = (pageTypeSelector, detailsForm) => {
    const hiddenPageCheckbox = detailsForm.querySelector("input#page_hidden_page");
    const hiddenPageCheckboxLabel = detailsForm.querySelector(".js-hidden-page-wrapper label");

    if (pageTypeSelector.value === "") {
      removeClassFromDetailsForm(detailsForm, "pagetype-non-standard");
      removeClassFromDetailsForm(detailsForm, "pagetype-template-with-banner");
      showHideParentPagePicker(detailsForm, true);
    } else {
      addClassToDetailsForm(detailsForm, "pagetype-non-standard");
      switch (pageTypeSelector.value) {
        case "accessories":
        case "blog":
        case "brand_dealers":
        case "brand_specials":
        case "build_and_buy":
        case "advanced_service_booking_form":
        case "dealer_specials":
        case "finance_calculator":
        case "group_dealers":
        case "stock":
        case "team_members":
        case "testimonials":
          addClassToDetailsForm(detailsForm, "pagetype-template-with-banner");
          break;
        default:
          removeClassFromDetailsForm(detailsForm, "pagetype-template-with-banner");
      }
      showHideParentPagePicker(detailsForm, false);
    }

    if (pageTypeSelector.value === "new_vehicle_showroom") {
      const newVehiclesSectionId = detailsForm.querySelector("input#page_page_section_id_hidden").dataset.newVehiclesId;

      disableHiddenPageCheckbox(hiddenPageCheckbox, hiddenPageCheckboxLabel);
      handleSelect(detailsForm, "page_page_section_id", true, newVehiclesSectionId);
    } else {
      enableHiddenPageCheckbox(hiddenPageCheckbox, hiddenPageCheckboxLabel);
      handleSelect(detailsForm, "page_page_section_id", false);
    }
  };

  const removeClassFromDetailsForm = (detailsForm, formClass) => {
    detailsForm.classList.remove(formClass);
  };

  const addClassToDetailsForm = (detailsForm, formClass) => {
    detailsForm.classList.add(formClass);
  };

  const disableHiddenPageCheckbox = (hiddenPageCheckbox, hiddenPageCheckboxLabel) => {
    hiddenPageCheckbox.checked = false;
    hiddenPageCheckbox.disabled = true;
    hiddenPageCheckboxLabel.classList = "disabled";
  };

  const enableHiddenPageCheckbox = (hiddenPageCheckbox, hiddenPageCheckboxLabel) => {
    hiddenPageCheckbox.disabled = false;
    hiddenPageCheckboxLabel.classList = "";
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.pageDetailsForm = pageDetailsForm();
