CMS = CMS || {};

const testimonial = () => {
  const loads = ["filter_testimonials"];

  const filtering_options = {
    valueNames: [{ data: ['title', 'name'] }],
    listClass: "js-filtering-list-container",
    searchClass: "js-search",
  };

  const filterTestimonials = () => {
    const filteringContainers = document.querySelectorAll('[data-filter="testimonials-filter"]');
    CMS.search_list.create_searching_lists(filteringContainers, filtering_options);
  };

  return {
    loads: loads,
    filter_testimonials: filterTestimonials,
  };
};

CMS.testimonial = testimonial();
