CMS = CMS || {};

const partials_injector = () => {
  const loads = [];
  /**
   *
   * @param {Node} target the targeted a tag
   * @param {Node} parent the parent of the injected partial
   * @param {String} partial to be injected html string. Partial has to be wrapped in single html element just like React components.
   */
  const injectPartial = (target, parent, partial) => {
    // insertBefore only accepts Node type arguments. No html strings
    // Create intermediary html element to parse the html string to nodes
    let tempDiv = fillTemperoryNode(partial);
    parent.insertBefore(tempDiv.firstChild, target);
    tempDiv = null;
  };

  /**
   *
   * @param {Node} parent the parent of the injected partial
   * @param {String} partial to be injected html string. Partial has to be wrapped in single html element just like React components.
   */
  const inectPartialInParent = (parent, partial) => {
    let tempDiv = fillTemperoryNode(partial);
    parent.appendChild(tempDiv.firstChild);
    tempDiv = null;
  };

  const fillTemperoryNode = partial => {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = partial;
    return tempDiv;
  };

  return {
    loads: loads,
    inject_before_target: injectPartial,
    inject_last_inside_parent: inectPartialInParent
  };
};

CMS.partials_injector = partials_injector();
