CMS = CMS || {};

const pageSectionForm = () => {
  const loads = ["handle_form_on_load"];
  const handleFormOnLoad = () => {
    const pgSectionForm = document.querySelector("#page_section_form");
    if (!pgSectionForm) return false;

    const optionTypeRadioButton = pgSectionForm.querySelectorAll(".js-cta-type-clicker");

    handlePageSectionRadioButton(pgSectionForm);

    optionTypeRadioButton.forEach((radioButton) => {
      radioButton.addEventListener("click", async () => {
        handlePageSectionRadioButton(pgSectionForm);
      });
    });
  };

  const handleSelect = (pageSectionForm, selectId, disabled, selectedValue = "") => {
    const selectElement = pageSectionForm.querySelector(`select#${selectId}`);

    if (!selectElement) return;

    const hiddenInput = pageSectionForm.querySelector(`input#${selectId}_hidden`);

    if (disabled) selectElement.value = selectedValue;
    selectElement.disabled = disabled;
    if (hiddenInput) {
      hiddenInput.value = selectedValue;
      hiddenInput.disabled = !disabled;
    }
  };

  // clear all classes first then add one class
  const clearAllThenAddClass = (theTargetElement, theClass) => {
    if (!theTargetElement.classList.contains(theClass)) {
      theTargetElement.className = "";
      theTargetElement.classList.add(theClass);
      return;
    }
  };

  const handlePageSectionRadioButton = (pgSectionForm) => {
    const checkedValue = pgSectionForm.querySelector("input.js-cta-type-clicker:checked").value;
    var url_input = pgSectionForm.querySelector("input#page_section_default_url_option");
    const formFieldset = pgSectionForm.querySelector("fieldset");

    switch (checkedValue) {
      case "none":
        url_input.value = ""; // Remove the value from the External and Internal Links fields so graphql returns empty string when None is selected
        clearAllThenAddClass(formFieldset, "option-none-selected");
        return;
      case "new_vehicles":
        url_input.value = ""; // Remove the value from the External and Internal Links fields so graphql returns empty string when New Vehicles is selected
        clearAllThenAddClass(formFieldset, "new-vehicles-selected");
        return;
      case "internal_url_override":
        pgSectionForm.querySelector("label#link").innerHTML = "Internal Link<sup class='imf-mandatory'>*</sup>";
        url_input.placeholder = "e.g. /new-cars?vehicleType=New";
        handleSelect(pgSectionForm, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "internal-url-selected");
        return;
      case "external_url_override":
        url_input.placeholder = "e.g. https://www.somewebsite.com.au/page";
        pgSectionForm.querySelector("label#link").innerHTML = "External Link<sup class='imf-mandatory'>*</sup>";
        handleSelect(pgSectionForm, "page_page_section_id", false);
        clearAllThenAddClass(formFieldset, "external-url-selected");
        return;
    }
  };
  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.page_section_type_radio_button = pageSectionForm();
