/* global dataLayer */
window.dataLayer = window.dataLayer || [];
window.gtag = function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

document.addEventListener("turbolinks:load", function () {
  gtag("config", process.env.TRACKING_ID);
});

export default gtag
