class AddStock {
  constructor() {
    this.addStockButton = document.getElementById("add-stock-button");
    this.typeSelect = document.getElementById("stock_add_type");
    this.feedIdSelect = document.getElementById("feed_id");
    this.init();
  }

  init() {
    if (!this.typeSelect || !this.feedIdSelect) return;
    this.typeSelect.addEventListener("change", (event) => {
      this.setAddStockButton(event.target.value, this.feedIdSelect.value);
    });
    this.feedIdSelect.addEventListener("change", (event) => {
      this.setAddStockButton(this.typeSelect.value, event.target.value);
    });
    const event = new Event("change");
    this.typeSelect.dispatchEvent(event);
  }

  setAddStockButton(type, feedId) {
    this.addStockButton.href = this.addStockButton.dataset.path + "/" + type + "/" + feedId;
    this.addStockButton.removeAttribute("disabled");
  }
}

module.exports = AddStock;
