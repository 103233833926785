CMS = CMS || {};

const otpResendCounter = () => {
  const loads = ["otp_resend_button"];

  const otpResendButton = () => {
    const resend_button = document.querySelector(".js-resend-otp-button");
    if (resend_button) {
      resend_button.disabled = true;
      resend_button.removeAttribute("href");
      // Set the countdown time in milliseconds
      var countDownTime = 60000;

      // Set the countdown interval
      const countdownInterval = setInterval(() => {
        // Decrement the countdown time
        countDownTime -= 1000;

        // Update the countdown display
        resend_button.innerText = resend_button.textContent = 'Resend after ' + countDownTime / 1000 + ' seconds';

        // If the countdown time has reached zero, disable the resend button
        if (countDownTime <= 0) {
          resend_button.innerText = resend_button.textContent = 'Resend OTP';
          resend_button.classList.remove("imf-btn-disabled");
          resend_button.classList.remove("imf-btn-kill");
          resend_button.href = resend_button.dataset.url;
          resend_button.disabled = false;

          // Clear the countdown interval
          clearInterval(countdownInterval);
        }
      }, 1000);
    }
  };

  return {
    loads: loads,
    otp_resend_button: otpResendButton,
  };
};

CMS.otpResendCounter = otpResendCounter();
