CMS = CMS || {};

const searchBar = () => {
  const loads = ["filtering_items"];

  const filtering_options = (searchAttributes) => {
    const filtering_options_object = {
      valueNames: [{ data: searchAttributes }],
      listClass: "js-filtering-list-container",
      searchClass: "js-search",
    };
    return filtering_options_object;
  };

  const filterItems = () => {
    const filteringContainers = document.querySelectorAll('[data-filter="filter-items"]');

    filteringContainers.forEach((container) => {
      const searchFilterAttributesArray = container.dataset["filter_attributes"].split(",").map((item) => {
        return item.trim();
      });
      CMS.search_list.create_searching_list(container, filtering_options(searchFilterAttributesArray));
    });
  };

  return {
    loads: loads,
    filtering_items: filterItems,
  };
};

CMS.searchBar = searchBar();
