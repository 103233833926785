CMS = CMS || {};

const wikiHelpBar = () => {
  const loads = ["help_bar"];

  const fetchMatchingWikis = async () => {
    try {
      let response = await fetch("/matching_wikis?url=" + window.location.pathname.replace(/^\/|\/$/g, ""));
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  };

  const closeWikiSidebar = (wiki_side_bar, main_div) => {
    wiki_side_bar.style.width = "0";
    wiki_side_bar.classList.add("status-hidden");
    main_div.style.marginRight = "0";
  };

  const openWikiSidebar = (wiki_side_bar, main_div) => {
    wiki_side_bar.style.width = "350px";
    wiki_side_bar.classList.remove("status-hidden");
    main_div.style.marginRight = "350px";
  };

  const renderWikiHelpBar = async () => {
    const wiki_side_bar = document.querySelector("#js_content_wiki_side_bar");
    if (!wiki_side_bar) return false;

    const wiki_contents = document.querySelector("#js_content_wiki_contents");

    let matching_wikis = await fetchMatchingWikis();

    if (matching_wikis.length < 1) return false;

    const main_div = document.querySelector(".main");

    document.querySelector("#js_content_wiki_side_bar_open").addEventListener("click", async () => {
      openWikiSidebar(wiki_side_bar, main_div);
    });

    document.querySelector("#js_content_wiki_side_bar_close").addEventListener("click", async () => {
      closeWikiSidebar(wiki_side_bar, main_div);
    });

    for (var i = 0; i < matching_wikis.length; i++) {
      var wiki = matching_wikis[i];
      $(wiki_contents).append("<h3>" + wiki.title + "</h3>" + wiki.long_copy + "<hr/>");
    }
  };

  return {
    loads: loads,
    help_bar: renderWikiHelpBar,
  };
};

CMS.wikiHelpBar = wikiHelpBar();
