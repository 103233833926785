/*

Toggle the visibility of an element from a link. e.g.

```
  <a href="#brand-level-configuration" class="js-toggle-view">view</a>

  <div id="brand-level-configuration" class="is-not-visible">
    Content hidden by default
  </div>
```
*/

CMS = CMS || {};

const visibility_toggler = () => {
  const loads = [];

  const toggle_visibility = e => {
    e.preventDefault();
    var toggle_target = e.target.getAttribute("href").split("#")[1];
    toggle_visibility_action(toggle_target, e.target);
  };

  const toggleVisibilityByNode = node => {
    if (node.classList.contains("is-visible")) {
      remove_visibility(node);
    } else {
      add_visibility(node);
    }
  };

  const toggle_visibility_action = (element_id, toggler_element) => {
    var target_element = document.getElementById(element_id);
    if (target_element !== null) {
      if (target_element.classList.contains("is-visible")) {
        remove_visibility(target_element);
        //toggler_element is the a tag or the button triggered the toggling action
        toggler_element.innerText =
          toggler_element.getAttribute("data-on_text") === null
            ? toggler_element.innerText
            : toggler_element.getAttribute("data-on_text");
      } else {
        add_visibility(target_element);
        //toggler_element is the a tag or the button triggered the toggling action
        toggler_element.innerText =
          toggler_element.getAttribute("data-off_text") === null
            ? toggler_element.innerText
            : toggler_element.getAttribute("data-off_text");
      }
    }
  };

  const add_visibility = element => {
    element.classList.remove("is-not-visible");
    element.classList.add("is-visible");
  };

  const remove_visibility = element => {
    element.classList.remove("is-visible");
    element.classList.add("is-not-visible");
  };

  return {
    loads: loads,
    toggle_visibility: toggle_visibility,
    toggle_visibility_by_node: toggleVisibilityByNode
  };
};

CMS.visibility_toggler = visibility_toggler();
