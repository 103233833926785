CMS = CMS || {};

const vehicle_trim = () => {
  const loads = ["toggle_options_visibility"];

  /**
   * in the trims summary tab to show or hide the extra elements of the form
   * */
  const toggleOptionsVisibility = () => {
    const trigger_element = document.querySelector(".js-toggle-trim-view");
    if (trigger_element !== null) {
      trigger_element.addEventListener("click", e => {
        CMS.visibility_toggler.toggle_visibility(e);
        false;
      });
    }
  };

  return {
    loads: loads,
    toggle_options_visibility: toggleOptionsVisibility,
  };
};

CMS.vehicle_trim = vehicle_trim();
