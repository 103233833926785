/*
 * This file is used to create a choices <select> element where, options can be searched for in the select using ChoicesJS.
 * There are 2 way to use this:
 *
 * 1 - Automatically initiate choices in a form - simply add the following markup to an erb file (make sure class names match)

    <div class="js-automatically-initiated-choices-select-wrapper">
      <select hidden
              class="js-choices-list"
              data-on-page-load-selected-values="<%= body_type.value || [] %>"
              data-choices-multiple="true"
              data-name-field="stock_setting_body_type[value][]"
              data-allow-item-remove-button="true"
              data-placeholder="Click to Add a Body Tag">
        <%= options_for_select(@stock_tags) %>
      </select>
    </div>

  2 - Manually initiate choices based on an event - below is an example that triggers using the "js-equals-rules-choices-select-wrapper".
      If you manually initiate choices, you will need to attach custom js to your class name (distict class name required)
    <div class="js-equals-rules-choices-select-wrapper">
      <select hidden
              class="js-choices-list"
              data-on-page-load-selected-values='<%= @rule.make_values %>'
              data-choices-multiple='true'
              data-allow-item-remove-button="true"
              data-name-field="collection_equals_rule[make_values][]"
              data-request-url="<%= all_stock_stock_path(:make) %>">
        <%#= options_for_select(Stock::Stock.not_archived.distinct.pluck(:make).compact.map { |m| [ m.humanize, m ] }) %>
      </select>
    </div>

 * The above is all you need to get it working.
 * Passing values into the "data-on-page-load-selected-values" attribute, allows for pre-selected options.
 * "data-allow-item-remove-button" is not required. If set to "false" it means users can't remove items from the <select>
 * Also the code snippet above can be used as many times as needed on a single page - without needing to adjust class names.
 * Js will be able to handle and distinguish between elements and clicks to make the desired changes.
 *
 * Finally: The code block above is actually creating a hidden select,
 * then the attributes from it are then used to create the actual choices <select>, using the choices library.

 * The <select> options can either be added by setting them manually (eg: passing in <option>...</option> in <select>),
 * or by using a url to source (fetch) data, which is defined in the "data-request-url" attribute.
*/

import Choices from "choices.js";

CMS = CMS || {};

const choices = () => {
  const loads = [];

  const createChoicesSelect = async (element) => {
    let hiddenSelectOptionsList = element.querySelector(".js-choices-list");
    if (!hiddenSelectOptionsList) return false;

    // Convert the <select> data attribute to an array format - for iteration - this is the saved values coming from the db
    let onPageLoadSelectedValues = hiddenSelectOptionsList.dataset["onPageLoadSelectedValues"];
    let allowItemRemoveButton = hiddenSelectOptionsList.dataset["allowItemRemoveButton"];
    allowItemRemoveButton = allowItemRemoveButton == "false" ? false : true;

    try {
      onPageLoadSelectedValues = convertDataToArray(onPageLoadSelectedValues);
      // Load data for <select> options using a server request (if request path exists). Otherwise use hardcoded options in the <select>
      let requestUrl = hiddenSelectOptionsList.dataset["requestUrl"];
      let choicesList;
      if (requestUrl) {
        let availableChoices = await fetchData(requestUrl);
        choicesList = createChoicesListFromJson(availableChoices, onPageLoadSelectedValues);
      } else {
        // Iterate through each option in the <select> and push it to the choicesList array - setting if its selected
        choicesList = createChoicesListFromMarkup(hiddenSelectOptionsList, onPageLoadSelectedValues);
      }

      // Create new select element and add it to selectWrapper
      if (choicesList) {
        var choicesMultiple = hiddenSelectOptionsList.dataset["choicesMultiple"];
        var selectElement = document.createElement("select");
        selectElement.multiple = choicesMultiple == "true" ? true : false;
        selectElement.name = hiddenSelectOptionsList.dataset["nameField"];
        selectElement.classList.add("js-choices-multiples-select");
        element.append(selectElement);
      }

      selectElement = element.querySelector(".js-choices-multiples-select");
      new Choices(selectElement, {
        removeItemButton: allowItemRemoveButton,
        choices: choicesList,
        addItems: true,
        shouldSort: false,
      });
    } catch (e) {
      console.error(e.message, `Error occurred when calling "${e.methodName}"`);
    }
  };

  const fetchData = async (url) => {
    try {
      let response = await fetch(url);
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  };

  const createChoicesListFromJson = (availableChoices, arrayOfValuesIndicatingSelectedOptions) => {
    let choicesList = [];
    availableChoices.forEach((choice) => {
      // This method returns a structure like -> [{label: "stock_1", value: "stock 1", selected: true/false},{...}]
      // Where "selected" is set to true for an iteration, if its found in "arrayOfValuesIndicatingSelectedOptions"
      let isItemSelected = arrayOfValuesIndicatingSelectedOptions.includes(choice[0]);
      choicesList.push({
        label: choice[1],
        value: choice[0],
        selected: isItemSelected,
      });
    });
    return choicesList;
  };

  const createChoicesListFromMarkup = (selectOptionsList, arrayOfValuesIndicatingSelectedOptions) => {
    let choicesList = [];
    selectOptionsList.forEach((choice) => {
      // This method returns a structure like -> [{label: "stock_1", value: "stock 1", selected: true/false},{...}]
      // Where "selected" is set to true for an iteration, if its found in "arrayOfValuesIndicatingSelectedOptions"
      let choiceValue = parseInt(choice["value"]);
      if (isNaN(choiceValue)) choiceValue = choice["value"];
      let isItemSelected = arrayOfValuesIndicatingSelectedOptions.includes(choiceValue);
      choicesList.push({
        label: choice["label"],
        value: choiceValue,
        selected: isItemSelected,
      });
    });
    return choicesList;
  };

  const convertDataToArray = (onPageLoadSelectedValues) => {
    // Convert string to an iteratable array.
    if (!onPageLoadSelectedValues) return [];
    if (typeof onPageLoadSelectedValues !== "string") {
      throw userException(
        "There must be data passed as arguments and it must be of string type.",
        "convertDataToArray"
      );
    } else if (onPageLoadSelectedValues == "[nil]") {
      onPageLoadSelectedValues = "[]";
    } else if (
      onPageLoadSelectedValues[0] !== "[" ||
      onPageLoadSelectedValues[onPageLoadSelectedValues.length - 1] !== "]"
    ) {
      let lastIndex = onPageLoadSelectedValues.length - 1;
      onPageLoadSelectedValues = onPageLoadSelectedValues.substring(0, 0) + "[" + onPageLoadSelectedValues;
      onPageLoadSelectedValues += onPageLoadSelectedValues.substring(lastIndex, lastIndex) + "]";
    }
    onPageLoadSelectedValues = JSON.parse(onPageLoadSelectedValues);
    return onPageLoadSelectedValues;
  };

  const userException = (message, methodName) => {
    return { message: message, methodName: methodName };
  };

  return {
    loads: loads,
    create_choices_select: createChoicesSelect,
  };
};

CMS.choices = choices();
