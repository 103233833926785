CMS = CMS || {};

const stock_location_states = () => {
  const loads = ["handle_form_on_load"];
  const handleFormOnLoad = () => {
    const stockLocationPg = document.querySelector("#location_location_country");
    if (!stockLocationPg) return false;

    const countryInputFields = document.querySelectorAll(".js-country-input-field");
    countryInputFields.forEach((input) => {
      input.addEventListener("change", async () => {
        handleCountrySelectedStates(input.value);
      });
    });
  };

  const handleCountrySelectedStates = (checkedValue) => {
    const statesWrapper = document.getElementById("js-state-wrapper");
    const inpuName = "location[location_state]";
    const inputId = "location_location_state";
    const auStates = statesWrapper.dataset.states.split(",");

    let inputTextValue = statesWrapper.dataset.value;

    let stateSelectField = document.createElement("select");
    stateSelectField.name = inpuName;
    stateSelectField.id = inputId;
    let option = document.createElement("option");
    option.text = "Please choose a state";
    stateSelectField.add(option);
    auStates.forEach((state) => {
      let option = document.createElement("option");
      option.text = state;
      option.value = state;
      stateSelectField.add(option);
      if (option.value == inputTextValue) option.selected = true;
    });

    let stateTextField = document.createElement("input");
    stateTextField.type = "text";
    stateTextField.name = inpuName;
    stateTextField.id = inputId;
    if (auStates.indexOf(inputTextValue) == -1) stateTextField.value = inputTextValue;
    document.getElementById(inputId).remove();

    switch (checkedValue) {
      case "Australia":
        statesWrapper.appendChild(stateSelectField);
        return;
      default:
        statesWrapper.appendChild(stateTextField);
        return;
    }
  };

  return {
    loads: loads,
    handle_form_on_load: handleFormOnLoad,
  };
};

CMS.stock_location_states = stock_location_states();
