CMS = CMS || {};

const checkBoxDivShowHide = () => {
  const loads = ["add_checkbox_event_listeners"];

  const addCheckboxEventListeners = () => {
    const checkboxes = document.querySelectorAll(".js-check-box-show-hide-clicker");

    if (null !== checkboxes) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", toggleDependentBlock);
      });
    }
  };

  const toggleDependentBlock = (event) => {
    const dependentBlocks = document.querySelectorAll(".js-check-box-show-hide-div");
    const checkboxState = event.target.checked;

    if (null == checkboxState || !checkboxState) {
      dependentBlocks.forEach((dependentBlock) => {
        dependentBlock.classList.remove("is-visible");
        dependentBlock.classList.add("is-not-visible");
      });
    } else {
      dependentBlocks.forEach((dependentBlock) => {
        dependentBlock.classList.remove("is-not-visible");
        dependentBlock.classList.add("is-visible");
      });
    }
  };

  return {
    loads: loads,
    add_checkbox_event_listeners: addCheckboxEventListeners,
  };
};

CMS.check_box_div_show_hide = checkBoxDivShowHide();
