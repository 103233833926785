CMS = CMS || {};

const automaticallyInitiateChoicesSelect = () => {
  const loads = ["initiate_choices_select_on_page_load"];

  const initiateChoicesSelectOnPageLoad = () => {
    let selectWrapper = document.querySelectorAll(".js-automatically-initiated-choices-select-wrapper");

    if (!selectWrapper) return false;

    selectWrapper.forEach(async (element) => {
      await CMS.choices.create_choices_select(element);
      addPlaceholderToSearchInput(element);
    });
  };

  const addPlaceholderToSearchInput = (selectWrapper) => {
    const selectElement = selectWrapper.querySelector(".js-choices-list");
    const selectPlaceholder = selectElement.dataset["placeholder"];
    if (selectPlaceholder) selectWrapper.querySelector("input.choices__input").placeholder = selectPlaceholder;
  };

  return {
    loads: loads,
    initiate_choices_select_on_page_load: initiateChoicesSelectOnPageLoad,
  };
};

CMS.automaticallyInitiateChoicesSelect = automaticallyInitiateChoicesSelect();
