import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

CMS = CMS || {};

const dashboardData = () => {
  const loads = [
    "draw_line_chart",
    "set_calendar_dates",
    "draw_doughnut",
    "draw_horizontal_bar_chart",
    "draw_pie_chart",
  ];

  const setCalendarDates = () => {
    const radioFrequencyButtons = document.querySelectorAll('input[name="frequency"]');
    const filterForm = document.getElementById("js-dashboard-data-filter");
    const startDate = document.getElementById("start_date");
    const endDate = document.getElementById("end_date");
    //Manual changing of end or start date will trigger the form rendering
    if (startDate && filterForm) {
      startDate.addEventListener("change", function (event) {
        startDate.value = event.target.value;
        if (radioFrequencyButtons) {
          radioFrequencyButtons.forEach((button) => {
            button.removeAttribute("checked");
          });
        }
        filterForm.submit();
      });
    }

    if (endDate && filterForm) {
      endDate.addEventListener("change", function (event) {
        endDate.value = event.target.value;
        if (radioFrequencyButtons) {
          radioFrequencyButtons.forEach((button) => {
            button.removeAttribute("checked");
          });
        }
        filterForm.submit();
      });
    }

    if (radioFrequencyButtons && filterForm) {
      radioFrequencyButtons.forEach((button) => {
        button.addEventListener("change", function (event) {
          let frequency = event.target.value;

          if (frequency == "weekly") {
            setFirstAndLastDateOfWeek();
            filterForm.submit();
          } else if (frequency == "monthly") {
            setFirstAndLastDateOfMonth();
            filterForm.submit();
          } else if (frequency == "yearly") {
            setFirstAndLastDateOfYear();
            filterForm.submit();
          }
        });
      });
    }

    //Time zone problems with UTC mean we have to format with the covertDate function below to Remain in AEST Time Zone:
    function convertDate(date) {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString();
      var dd = date.getDate().toString();

      var mmChars = mm.split("");
      var ddChars = dd.split("");

      return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
    }

    let today = new Date();

    // Calculate 1 week from current date, and set start and end date.

    function setFirstAndLastDateOfWeek() {
      const lastWeekDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

      startDate.value = lastWeekDate.toISOString().slice(0, 10);
      endDate.value = today.toISOString().slice(0, 10);
    }

    //Calculate Current month start and end date
    function setFirstAndLastDateOfMonth() {
      function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
      }

      const firstDayOfMonth = getFirstDayOfMonth(today.getFullYear(), today.getMonth());
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      const formattedBeginningOfYear = convertDate(firstDayOfMonth);
      const formattedEndOfYear = convertDate(lastDayOfMonth);

      startDate.value = formattedBeginningOfYear;
      endDate.value = formattedEndOfYear;
    }

    //Calculate Current year, and set start and end date
    function setFirstAndLastDateOfYear() {
      const currentYear = new Date().getFullYear();
      const beginningOfYear = new Date(currentYear, 0, 1);
      const endOfYear = new Date(currentYear, 11, 31);

      const formattedBeginningOfYear = convertDate(beginningOfYear);
      const formattedEndOfYear = convertDate(endOfYear);

      startDate.value = formattedBeginningOfYear;
      endDate.value = formattedEndOfYear;
    }
  };

  const colourPalette = [
    "#579EC8",
    "#F4A938",
    "#D95843",
    "#4DAD7F",
    "#00466B",
    "#6A858C",
    "#67BCBF",
    "#086B63",
    "#77A490",
    "#E2D8C1",
    "#BFAE95",
    "#7C7159",
    "#4F4B44",
    "#913026",
    "#BD782D",
    "#C7B570",
    "#737144",
    "#9FB1BC",
    "#CC7E5B",
    "#F9BD98",
    "#001219",
    "#005f73",
    "#0A9396",
    "#94D2BD",
    "#E9d8A6",
    "#EE9B00",
    "#CA6702",
    "#BB3E03",
    "#AE2012",
    "#9B2226",
  ];

  const drawHorizontalBarChart = () => {
    const barCharts = document.querySelectorAll(".js-bar-chart");
    barCharts.forEach((ctx) => {
      if (ctx) {
        new Chart(ctx.getContext("2d"), {
          type: "bar",
          data: {
            labels: JSON.parse(ctx.dataset.labels),
            datasets: [
              {
                axis: "x",
                label: ctx.dataset.name,
                data: JSON.parse(ctx.dataset.data),
                fill: false,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: colourPalette,
              },
            ],
          },
          plugins: [ChartDataLabels],
          options: {
            indexAxis: "y",
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.5,
            plugins: {
              datalabels: {
                anchor: "end",
                align: "right",
                offset: 5,
                clamp: true,
                backgroundColor: "rgba(255,255,255, 0.2)",
                borderRadius: 5,
                color: "#333333",
              },
              legend: {
                display: false,
              },
            },
          },
        });
      }
    });
  };

  const drawPieChart = () => {
    const ctx = document.getElementById("js-pieChart");

    if (ctx) {
      new Chart(ctx.getContext("2d"), {
        type: "pie",
        data: {
          labels: JSON.parse(ctx.dataset.labels),
          datasets: [
            {
              label: ctx.dataset.name,
              data: JSON.parse(ctx.dataset.data),
              backgroundColor: colourPalette,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          aspectRatio: 1.3,
          maintainAspectRatio: true,
          indexAxis: "y",
          responsive: true,
          radius: "80%",
          rotation: 90,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 20,
                boxHeight: 20,
              },
            },
          },
        },
      });
    }
  };

  const drawLineChart = () => {
    const ctx = document.getElementById("js-lineChart");

    if (ctx) {
      new Chart(ctx.getContext("2d"), {
        type: "line",
        data: {
          labels: JSON.parse(ctx.dataset.labels),
          datasets: [
            {
              label: ctx.dataset.name,
              data: JSON.parse(ctx.dataset.data),
              fill: false,
              borderColor: colourPalette[0],
              backgroundColor: colourPalette[0],
              tension: 0.3,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: true,
              },
              beginAtZero: true,
              ticks: {
                stepSize: 5,
              },
            },
          },
        },
      });
    }
  };

  const drawDoughtnut = () => {
    const ctx = document.getElementById("js-doughnut");

    if (ctx) {
      new Chart(ctx.getContext("2d"), {
        type: "doughnut",
        data: {
          labels: JSON.parse(ctx.dataset.labels),
          datasets: [
            {
              label: "Total Leads",
              data: JSON.parse(ctx.dataset.data),
              backgroundColor: colourPalette,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          cutout: "50%",
          aspectRatio: 1.3,
          maintainAspectRatio: true,
          responsive: true,
          rotation: 90,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 20,
                boxHeight: 20,
              },
            },
          },
        },
      });
    }
  };

  return {
    loads: loads,
    draw_line_chart: drawLineChart,
    draw_horizontal_bar_chart: drawHorizontalBarChart,
    draw_pie_chart: drawPieChart,
    draw_doughnut: drawDoughtnut,
    set_calendar_dates: setCalendarDates,
  };
};

CMS.dashboard_data = dashboardData();
